import React,{useContext, useEffect} from 'react'
import TaskRow from './TaskRow'
import { UserTasks } from '../context/userTasks';
import {HideIncompleteTextContext} from'../context/HideIncompleteTextContext'

export default function TaskList({employeeId,disabledEdit}) {
  const [,,filteredIssues,noDateIssues,taskReq]=useContext(UserTasks)
  const [hideIncompleteText]= useContext(HideIncompleteTextContext)
  const [loading,setLoading] = React.useState(false)

  // initialize tasks
  useEffect(()=>{
    setLoading(true)
    taskReq(employeeId).finally(()=>{
      setLoading(false)
    })
  },[])

  return (
    <div>
      <div>
        {loading?<div className="TaskRowLoadingContainer"><div className="TaskRowLoading"></div></div>:filteredIssues.map((task,index)=><TaskRow disableEdit={disabledEdit} key={index} task={{...task,employeeId}} index={index}  />)}
        {!loading && !hideIncompleteText&&noDateIssues.map((task,index)=><TaskRow disableEdit={disabledEdit} key={index} index={index} task={{...task,employeeId}} noDate={true}/>)}
      </div>
    </div>
  )
}
