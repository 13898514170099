import React from 'react'
import {Badge, Row, Col, Form, CloseButton, Overlay, Tabs, Tab, } from 'react-bootstrap'
import { getAllTeams,searchUsers,getTeamMembers, getBulkUsers } from '../../../services/jira';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import { UserFilterContext } from '../context/UserFilterContext';
import _ from 'lodash'

const FilterSelector = ({hideFilters}) => {
    const [auth] = React.useContext(AuthContext);
    const target = React.useRef(null);
    const overlayRef = React.useRef(null);
    const [userFilter, setUserFilter] = React.useState('')
    const [teamList, setTeamList] = React.useState({});
    const [fetchedTeams,setFetchedTeams] = React.useState({})
    // const [roleList, setRoleList] = React.useState({});
    const [userList, setUserList] = React.useState({});
    const [filterIsOpen, setFilterIsOpen] = React.useState(false)
    const [filters, setFilters] = React.useState([]);
    const [filtersToShow,setFiltersToShow] = React.useState([])
    const [fetchedUsers, setFetchedUsers] = React.useState([])
    const [usersToShow,setUsersToShow,___,setLoadingUsersToShow] = useContext(UserFilterContext)
    const [loading] = React.useState(false)
    const debounceFilterUsers = React.useCallback(_.debounce(filterUsers, 500), []);

    React.useEffect(()=>{

        if(auth?.profileObj?.email && hideFilters===true){
            const email = auth.profileObj.email;
            searchUsers(auth.accessToken,email).then((response) => {
                const user= response.data[0];
                const fullUser = generateFullUser({member:{accountId:user.accountId,displayName:user.displayName}},user,"")
                const filteredUsers = filterDuplicatedUsers([user])
                // setFetchedUsers([...fetchedUsers,...filteredUsers])
                setUsersToShow([fullUser])
            })

        }
    },[auth,hideFilters])

    function removeFilter(index) {
        let newFilters = [...filtersToShow]
        newFilters.splice(index, 1)
        setFiltersToShow(newFilters)
        setFilters(newFilters)
    }

    function filterUsersToShow(usersToFilter){
        //filter duplicated users
        //in case some user is duped, prioritize the one with the team
        const uniqueItems = {}
        const uniqueItemsWithTeams = {}
        usersToFilter.forEach(item => {
            if (item.team) {
              if (!uniqueItemsWithTeams[item.key]) {
                uniqueItemsWithTeams[item.key] = item;
              }
            } else if (!uniqueItems[item.key]) {
              uniqueItems[item.key] = item;
            }
          });
          
        return Object.values([...Object.values(uniqueItems), ...Object.values(uniqueItemsWithTeams)]);
    }

    function addFilter(value, type) {
        let newFilters = [...filtersToShow]
        newFilters.push({ type, value })
        setFiltersToShow(newFilters)

        if(type==="user"){
            setFilters(newFilters)
        }

        if(type==="team"){
            const teamId = value.key
            fetchTeams(auth,teamId).then(members=>{

                const usersToGet = filterDuplicatedUsers(members.map((member)=>({id:member.member.accountId,...member})))
                if(usersToGet?.length>0){
                    fetchUsers(auth,usersToGet.map(user=>user.id)).then((fetchUserResult) => {
                        setFilters(newFilters)
                    })
                }else{
                    setFilters(newFilters)
                }

            })
        }
    }

    function fetchTeams (auth,teamId){
        return new Promise((resolve,reject)=>{
            setLoadingUsersToShow(true)
            if(fetchedTeams[teamId]) return resolve(fetchedTeams[teamId])
            getTeamMembers(auth.accessToken,teamId).then((response) => {
                const members = response.data.results
                setFetchedTeams({...fetchedTeams,[teamId]:members})
                return resolve(members)
            }).catch(e=>reject(e))
            .finally(()=>{setLoadingUsersToShow(false)})
        })
    }

    function fetchUsers (auth,users){
        return new Promise((resolve,reject)=>{
            setLoadingUsersToShow(true)
            getBulkUsers(auth.accessToken,users).then((response) => {
                const users = parseUsers(response.data.values)
                const filteredUsers = filterDuplicatedUsers(users.values)
                setFetchedUsers([...fetchedUsers,...filteredUsers])
                return resolve(users)
            }).catch(e=>reject(e))
            .finally(()=>{setLoadingUsersToShow(false)})
        })
    }

    function generateFullUser(tempoUser,jiraUser,teamId){
        return {
            name:tempoUser.member.displayName,
            accountId:tempoUser.member.accountId,
            id:tempoUser.member.accountId,
            key:tempoUser.member.accountId,
            role:tempoUser?.memberships?.active?.role?.name,
            avatar:jiraUser?.avatarUrls?.["48x48"] ?? jiraUser?.avatar ?? null,
            teamId:teamId
        }
    }

    function filterDupes(list, type) {
        if (!list || !list.length) return []
        return list.filter((item) => filtersToShow.findIndex((item2) => item2.type === type && item2.value.key === item.key) < 0)
    }

    function filterDuplicatedUsers (users) {
        return users
        //both arrays have the same structure, filter by id
        return users.filter((user) => {
            return !fetchedUsers.some((fetchedUser) => fetchedUser.accountId === user.id)
        })
    }

    function filterUsers(value,fetchedUsers) {

        if (value.length > 2) {
            //fetch users here
            searchUsers(auth.accessToken,value).then((response) => {
                const users = parseUsers(response.data)
                const filteredUsers = filterDuplicatedUsers(users.values)

                setFetchedUsers([...fetchedUsers,...filteredUsers])
                setUserList(users)
            }).catch(response=>{

            })
        }
    }

    function userInputOnChange(e) {
        const value = e.target.value
        debounceFilterUsers(value,fetchedUsers)
        setUserFilter(value)
    }

    function getTeams (){
        if(teamList?.isLast) return
        getAllTeams(auth.accessToken).then((response) => {
            setTeamList(parseTeams(response.data))
            //setLoading(false)
        })
    }
    function parseUsers (data){
        return {
            isLast:true,
            values:data.map((item) => ({
                name:item.displayName,
                email:item.emailAddress,
                id:item.accountId,
                key:item.accountId,
                avatar:item.avatarUrls["48x48"],
            }))
        }
    }

    function parseTeams(data){
        return {
            isLast:true,
            total:data.metadata.count,
            values:data.results.map((item) => ({
                name:item.name,
                id:item.id,
                key:item.id,
                membersLink:item.members.self
            }))
        }
    }

    
    const handleDocumentClick = (event) => {

        if ((overlayRef.current && !overlayRef.current.contains(event.target)) && (target.current && !target.current.contains(event.target))) {
            setFilterIsOpen(false);
        }
    }

    React.useEffect(()=>{
        getTeams()
        document.addEventListener('click', handleDocumentClick);

        return () => {
          document.removeEventListener('click', handleDocumentClick);
        };   
    },[])

    React.useEffect(()=>{
        if(filters.length) setUsersToShow([]);
        
        const newUsersToShow = []
        filters.forEach((filter)=>{
            if(filter.type == "user"){
                const userId = filter.value.key
                const jiraUser = fetchedUsers.find((newUser)=>newUser.id===userId)
                if(!jiraUser) return
                const fullUser = generateFullUser({member:{accountId:userId,displayName:jiraUser.name}},jiraUser,"")
                newUsersToShow.push(fullUser)
            }
            if(filter.type == "team"){
                const teamId = filter.value.key
                const teamMembers = fetchedTeams[teamId]
                if(!teamMembers) return
                const newUsers = teamMembers.map((tempoUser)=>{
                    const jiraUser = fetchedUsers.find((newUser)=>newUser.key===tempoUser.member.accountId)
                    if(!jiraUser) return null
                    return generateFullUser(tempoUser,jiraUser,teamId)
                }).filter((user)=>user)
                newUsersToShow.push(...newUsers)
            }

        })
        setUsersToShow([...filterUsersToShow(newUsersToShow)])
    },[filters])

    if(hideFilters) return <></>

    return (
        <>
            <Col ref={target} className="d-flex flex-row col-4 gap-4 justify-flex-start">
                <div onClick={(e) => setFilterIsOpen(!filterIsOpen)} className="hover-pointer d-flex flex-row gap-1 bg-white b-1 py-2 px-1 h-10 rounded max-w-260 overflow-hidden">
                    {!filtersToShow.length && <div className="text-gray-700 fw-bold d-flex align-items-center mx-2">Clique aqui para filtrar</div>}
                    {filtersToShow?.map((filter, index) =>
                        <div key={`badge-${filter?.value?.key}`} >
                            <Badge className="d-flex align-items-center fs-xs" bg={"secondary"}>{filter?.value?.name} <CloseButton onClick={() => removeFilter(index)} className='h-2' variant='white' /></Badge>
                        </div>
                    )}
                </div>
            </Col>
            <Overlay ref={overlayRef} target={target.current} show={filterIsOpen} placement="bottom">
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                }) => (
                    <div {...props} className="bg-gray100 rounded w-300 p-3 userSelectBox">
                        <Row>
                            {filtersToShow.map((filter, index) => (
                                <div>
                                    <Form.Group >
                                        <Form.Check onClick={() => removeFilter(index)} controlId={`${filter?.value?.key}`} checked inline type={"checkbox"} name={"view"} key={`filter-${filter?.value.key}`} />
                                        <Form.Label>{filter?.value?.name} </Form.Label>
                                    </Form.Group>
                                </div>
                            ))}
                        </Row>
                        <div>
                            <Tabs>
                                <Tab eventKey="team" title="Equipes">
                                {loading?<div className={`h-200 FilterLoading`}><div className="FilterLoadingSpinner"></div></div>:
                                    <div className="d-flex flex-column max-h-200 h-200 overflow-auto">
                                        {filterDupes(teamList?.values, "team").map((team, index) => (
                                            <div>
                                                <Form.Group className="user-name-wrapper" controlId={`team-${team.key}`} >
                                                    <Form.Check onClick={() => addFilter({name:team.name,key:team.key}, "team")} inline type={"checkbox"} name={"view"} key={`filter-team-${team.key}`} />
                                                    <Form.Label>{team?.name} </Form.Label>
                                                </Form.Group>
                                            </div>
                                        ))}
                                    </div>
                                }
                                </Tab>
                                <Tab eventKey="user" title="Usuário">
                                    <Form.Control value={userFilter} onChange={userInputOnChange} type="text" placeholder="Digite o nome do usuário" />
                                    <div className="d-flex flex-column max-h-200 overflow-auto">
                                        {filterDupes(userList?.values, "user").map((user, index) => (
                                            <Form.Group className="user-name-wrapper" controlId={`user-${user.key}}`}>
                                                <Form.Check onClick={() => addFilter({name:user.name,key:user.key}, "user")} inline type={"checkbox"} name={"view"} key={`filter-user-${user.key}`} />
                                                <Form.Label>{user.name} </Form.Label>
                                            </Form.Group>
                                        ))}
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                )}
            </Overlay>
        </>
    )
}

export default FilterSelector