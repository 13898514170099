import { Box,Input} from '@chakra-ui/react'
import React from 'react'
const SearchInput = ({onChangeFunction,placeholder,isDisabled}) => {
    const [value,setValue] = React.useState('')

    function handleOnChange(e){
        setValue(e.target.value)
        if(typeof onChangeFunction == "function"){
            onChangeFunction(e)
        }
    }
  return (
    <Box maxW={'300px'} flex={'1'}>
        <Box mb={1} fontWeight='bold'>Busca</Box>
        <Input isDisabled={!!isDisabled} w={'100%'} background='white' value={value} onChange={(e)=>handleOnChange(e)} placeholder={placeholder||"Digite o nome de um projeto"} fontSize={'sm'} />
    </Box>
  )
}

export default SearchInput