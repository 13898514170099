
//create context

import React from "react";

const GroupingTypeContext = React.createContext();

//create provider

const GroupingTypeProvider = ({ children }) => {
    const [groupingType, setGroupingType] = React.useState("days");
    
    return (
        <GroupingTypeContext.Provider value={[groupingType, setGroupingType ]}>
            {children}
        </GroupingTypeContext.Provider>
    );
}

export { GroupingTypeContext, GroupingTypeProvider };