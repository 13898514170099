import React from 'react'

const HideIncompleteTextContext = React.createContext();

const HideIncompleteTextProvider = ({ children }) => {
    const [hideIncompleteText, setHideIncompleteText] = React.useState(false);

    return (
        <HideIncompleteTextContext.Provider value={[hideIncompleteText, setHideIncompleteText]}>
            {children}
        </HideIncompleteTextContext.Provider>
    );
}

export { HideIncompleteTextContext, HideIncompleteTextProvider };