import React, {  useEffect } from 'react';
import { Box,Flex,} from '@chakra-ui/layout'
import { AuthContext } from '../../context/AuthContext';
import NewSideBar from '../Sidebar/NewSideBar';

function Home() {
  const [auth] = React.useContext(AuthContext);

    if(!auth){
        return
    }

  return (
      <Box flexBasis='100%' mr={0} ml={'auto'} px={'4%'} pt={3} overflow={'auto'}>
          {":)"}
      </Box>
  )
}

export default Home;