import styled, { css } from 'styled-components'

const spinnerModifier = {
    loading: () => css`
        & > .chakra-spinner{
            display: block;
            & + div {
                opacity: .3;
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    `
}

export const SpinnerWrapper = styled.div`
    ${({ loading }) => css`
        position: relative;
        width: 400px;
        & > .chakra-spinner{
            display: none;
            position: absolute;
            z-index: 10;
            left: calc(50% - 15px);
            top: calc(50% - 15px);
        }

        ${loading && spinnerModifier.loading()}
    `}
`