import { ChakraProvider, Flex, Heading, extendTheme } from "@chakra-ui/react";
import React from "react";
import NewSideBar from "../Sidebar/NewSideBar";
import { isAllowed } from "../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import permissionSchema from "../../utils/permissionSchema";
import { PermissionContext } from "../../context/PermissionContext";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const Unauthorized = () => {
    return (
        <Flex justifyContent={"center"} alignItems={"center"} h={"100%"} w={'100%'} flexDir={"column"}>
            <FontAwesomeIcon icon={faExclamationTriangle} size="6x" />
            <Heading fontSize={"24px"} p={2}>
                Não há nada aqui...
            </Heading>
        </Flex>
    );
};

export const theme = extendTheme({
    fonts: {
        heading: 'Roboto, Times New Roman, sans-serif',
        body: 'Roboto, Times New Roman, sans-serif',
    },
    textStyles: {
        body: {
            fontFamily: 'Roboto, Times New Roman, sans-serif',
        },
        heading: {
            fontFamily: 'Roboto, Times New Roman, sans-serif',
        },
        mono: {
            fontFamily: 'Roboto, Times New Roman, sans-serif',
        },
    },
})

const PageWithMenu = ({ props,activeLink,useChakra}) => {
    const [permissions] = React.useContext(PermissionContext);
    const [allowed] = React.useState(permissionSchema[window.location.pathname.replace(/\/*$/, "")] || [0]);
    const [isLeastPermission, setIsLeastPermission] = React.useState();
    const publicRoutes = ['/planning']
    const isPublicRoute = publicRoutes.includes(window.location.pathname.replace(/\/*$/, ""))

    React.useEffect(() => {
        if (permissions) {
            setIsLeastPermission(!isAllowed(permissions, allowed));
        }
    }, [permissions,allowed]);

    const clonedElement = React.cloneElement(props, { isLeastPermission })

    if (useChakra) {
       return <ChakraProvider theme={theme}>
            <Flex direction="row" h={"100%"} fontFamily={"Roboto"}>
                <NewSideBar activeLink={activeLink}/>
                {isLeastPermission === true && isPublicRoute === false ? (
                    <Unauthorized />
                ) : (
                    <>
                        {clonedElement}
                    </>
                )}
            </Flex>
        </ChakraProvider>
    }

    return (
        <Flex direction="row" h={"100%"} fontFamily={"Roboto"}>
            <ChakraProvider theme={theme}>
                <NewSideBar activeLink={activeLink}/>
            </ChakraProvider>
            {isLeastPermission === true && isPublicRoute === false ? (
                <Unauthorized />
            ) : (
                <>
                    {clonedElement}
                </>
            )}
        </Flex>
    );
};

export default PageWithMenu;
