import React from 'react'

const AuthContext = React.createContext();
const AuthProvider = (props) => {
    const [auth,setAuth] = React.useState()
    return (
        <AuthContext.Provider value={[auth,setAuth]}>
            {props.children}
        </AuthContext.Provider>
    )
}

export {AuthContext,AuthProvider}
