import React,{useContext} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight,faChevronDown } from '@fortawesome/free-solid-svg-icons'
import UsedHours from './UsedHours';
import {DateRangeContext} from '../context/DateRangeContext'
import { UserTasks } from '../context/userTasks';
import convertDecimalToTime from '../utils/convertDecimalToTime'
export default function UserRow({index, openList,employee,handleOpen}) {
   
    const [,,filteredIssues,,]=useContext(UserTasks)
    const [,,datesBetween ] = useContext(DateRangeContext);
    const totalHours = datesBetween.reduce((accumulator, currentValue) =>{
        if(currentValue.isWeekDay){
            accumulator+= 8
        }
        return accumulator
    },0)

    const dateAndHours = filteredIssues.reduce((accumulator, issue) => {
        if (!issue?.fields?.customfield_10022 || !issue?.fields?.customfield_10023) return accumulator;
        const initialDate = issue.fields.customfield_10022;
        const finalDate = issue.fields.customfield_10023;
        
        
        datesBetween.forEach(day => {
          if (day.dateDataBase >= initialDate && day.dateDataBase <= finalDate && day.isWeekDay) {
            if (!accumulator[day.formatedDate]) {
              accumulator[day.formatedDate] =0;
            }
            accumulator[day.formatedDate] += issue.hoursPerDayDecimal; 
          }
        });
      
        return accumulator;
      }, {});

      const totalUsedHours = Object.values(dateAndHours).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    return (
        <div key={index} className="border-radius  m-0  employee d-flex" onClick={()=>handleOpen(index)}>
            <div className='leftCol '>
                <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={openList.includes(index)?faChevronDown:faChevronRight} size="1x" className='arrowIcon'/>
                    <img alt={employee.displayName} src={employee.avatar} className='employeeAvatar rounded-pill ' />
                    <div>
                        <p>
                            {employee.name}
                        </p>
                        <p>
                            {employee.role}
                        </p>
                    </div>
                </div>
            <div  className='d-flex align-items-center '><p className='totalHoursPreview'>{<strong>{convertDecimalToTime(totalUsedHours)}h</strong>} / {convertDecimalToTime(totalHours)}h</p></div>
            </div>
            <UsedHours  dateAndHours={dateAndHours}  />        
        </div>
    )
}
