//create context

import React from "react";
import DateObject from "react-date-object";
import { formatDate } from '../../Planning/utils/formatDate'
import { getIfWeekendOrHoliday } from "../../../utils/functions";

const DateRangeContext = React.createContext();

function getDefaultDate(startDate, endDate) {
    if (startDate && endDate) {
        return [new DateObject(new Date(startDate)), new DateObject(new Date(endDate))]
    }
    return [new DateObject(new Date()), new DateObject(new Date()).add(14, 'day')]
}

function getDatesBetween(startDate, endDate) { //pega as datas entre a inicial e a final
    const dates = [];
    let nOfWeekdays = 0;
    const currentDate = new Date(startDate);
    //adjust dates to brazilian time
    currentDate.setHours(currentDate.getHours() - 3)
    endDate.setHours(endDate.getHours() - 3)


    while (currentDate <= endDate) {
        let isWeekDay = false;
        if (getIfWeekendOrHoliday(currentDate)) {
            isWeekDay = true
            nOfWeekdays++
        }
        const formattedDate = {
            date: new Date(currentDate).setHours(0, 0, 0, 0),
            ...formatDate(currentDate),
            isWeekDay
        };
        dates.push(formattedDate);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return [dates, nOfWeekdays];
}


const DateRangeProvider = ({ children, startDate, endDate }) => {
    const [dateRange, setDateRange] = React.useState(getDefaultDate(startDate, endDate));
    const [datesBetween, nOfWeekdays] = getDatesBetween(new Date(dateRange[0]), new Date(dateRange[1]));


    return (
        <DateRangeContext.Provider value={[dateRange, setDateRange, datesBetween, nOfWeekdays]}>
            {children}
        </DateRangeContext.Provider>
    );
}

export { DateRangeContext, DateRangeProvider };