import React from "react";
import { Flex, Box, Text, IconButton, useDisclosure, Accordion, Switch, Button, Skeleton, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Heading, Table, Thead, Tr, Th, Tbody, Td, Stack, Input, InputRightAddon, InputGroup, Select } from "@chakra-ui/react";
import { UilPlus,UilTrashAlt } from "@iconscout/react-unicons";

const y300 = { bg: "yellow.300", borderColor: "yellow.300" };
const y400 = { bg: "yellow.400", borderColor: "yellow.400" };

const r300 = { bg: "red.300", borderColor: "red.300" };
const r400 = { bg: "red.400", borderColor: "red.400" };

const JiraAccountExtraTime = ({accountCycles,accountDetails,setAccountCycles,isAccountClosed}) => {
    const [extraTimeValue,setextraTimeValue] = React.useState(null)
    const [selectValue,setSelectValue] = React.useState(accountDetails.startCycle)

    React.useEffect(()=>{
        if(accountDetails.startCycle) setSelectValue(accountDetails.startCycle)
    },[accountDetails.startCycle])

    function extraTimeInput(e) {
        let value = e.target.value;
        if(!value) value = 0
        if (!e.target.validity.valid || isNaN(value)) return;
        setextraTimeValue(Number(value))
    }

    function addExtraTime(e) {
        if(!extraTimeValue || !selectValue) return
        let newAccountCycles = JSON.parse(JSON.stringify(accountCycles))
        let cycle = newAccountCycles.find(cycle=>cycle.cycleNumber == selectValue)
        if(cycle){
            if(!cycle.extraTime){
                cycle.extraTime = []
            }
            cycle.extraTime.push({
                time:extraTimeValue*3600,
                createdAt:new Date(),
                cycleNumber:selectValue
            })
        }
        setAccountCycles(newAccountCycles)
        
    }
    function getSelectValues(){
        let opts = []
        for(let i=accountDetails.startCycle;i<=accountDetails.endCycle;i++){
            opts.push(<option key={"option-"+i} value={i}>Ciclo {i}</option>)
        }
        return opts
    }

    function handleSelectChange(e){
        setSelectValue(e.target.value)

    }

    function getExtraTime(){
        let eh = []
        let startCycle = accountDetails.startCycle
        let endCycle = accountDetails.endCycle
        accountCycles.forEach((cycle)=>{
            if(cycle?.extraTime?.length && cycle?.cycleNumber>=startCycle && cycle?.cycleNumber<=endCycle ){
                cycle.extraTime.forEach((extraTime,index)=>{
                    eh.push({...extraTime,innerIndex:index})
                })
            }
        })
        return eh.sort((a,b)=>a.cycleNumber>b.cycleNumber)
    }

    function handleDeleteExtraTime({cycleNumber,innerIndex}){
        let newAccountCycles = JSON.parse(JSON.stringify(accountCycles))
        let cycle = newAccountCycles.find(cycle=>cycle.cycleNumber == cycleNumber)
        if(cycle){
            cycle.extraTime.splice(innerIndex,1)
        }
        setAccountCycles(newAccountCycles)
    }
    return (
        <Flex ml={2} flexDir={"column"} w={"100%"} flexGrow={1}>
            <Text fontSize={"sm"}>Horas Extras</Text>

            <InputGroup display={"flex"} sx={{gap:"12px"}} size={"sm"} pb={3}>
                <Select iconSize="16px" minW={"100px"} maxW={"140px"} value={selectValue} onChange={(e)=>handleSelectChange(e)}>
                    {getSelectValues()}
                </Select>
                <InputGroup maxW={"100px"}>
                    <Input disabled={isAccountClosed} type="tel" value={extraTimeValue} pattern="[0-9]*" background={"white"} onChange={(e) => extraTimeInput(e)} minW={"48px"}  placeholder="" />
                    <InputRightAddon justifyContent={"center"} p={0} w={"36px"} children="hrs" />
                </InputGroup>

                <IconButton disabled={isAccountClosed}  w={"32px"} size={"sm"} _hover={y300} onClick={(e) => addExtraTime(e)} _active={y400} icon={<UilPlus />} />
            </InputGroup>
            <Flex flex={1} maxHeight={"406px"} overflow={"auto"} border={"1px solid #f3f3f3"} borderRadius={"6px"} flexDir={"column"} sx={{gap:"8px"}} p={2}>
                {getExtraTime().map((extraTime,index)=>(
                    <Flex key={"extra-time-"+index} p={2} borderRadius={"6px"} backgroundColor={"#f3f3f3"} fontSize={"sm"} justifyContent={"space-between"}>
                        <Flex flexDir={"column"}>
                            <Text fontWeight={"bold"}>Ciclo</Text>
                            <Text>{extraTime.cycleNumber}</Text>
                        </Flex>
                        <Flex flexDir={"column"}>
                            <Text fontWeight={"bold"}>Tempo</Text>
                            <Text>{`${extraTime.time/3600} hrs`}</Text>
                        </Flex>
                        <IconButton disabled={isAccountClosed}  alignSelf={"center"} m={0} bg={"white"} _hover={r300} _active={r400} color={"black"} variant="outline" onClick={(e) => handleDeleteExtraTime({...extraTime})} size={"sm"} icon={<UilTrashAlt size={"18px"} />} />
                    </Flex>
                ))}
            </Flex>
        </Flex>
    );
};

export default JiraAccountExtraTime;
