import React from 'react';
import Routes from './routes';
import { AuthContext } from "./context/AuthContext"
import { PermissionContext } from './context/PermissionContext';
import GoogleAuth from './components/GoogleAuth';
import authValidator from './auth/authValidator';
function App() {
    const [auth, setAuth] = React.useContext(AuthContext);
    const [, setPermissions] = React.useContext(PermissionContext);
    const [loading,setLoading] = React.useState(true)

    //Isso resolve mas não gostei, refazer da forma abaixo:
    //salvar toda credencial no localstorage, e só recuperar quando o token for validado
    //state apenas para loading
    function checkIfValid(){
        authValidator()
        .then((resp)=>{
            let googleAuth = localStorage.getItem('googleAuth')?JSON.parse(localStorage.getItem('googleAuth')):''
            setLoading(false)
            setPermissions(resp)
            setAuth(googleAuth)
        })
        .catch(()=>{
            setPermissions()
            setAuth()
            setLoading(false)
        }) 
    }
      
    React.useEffect(()=>{
        if(localStorage.getItem('googleAuth')){
            checkIfValid()
        }else{
            setLoading(false)
        }
    },[])
    
    if(loading){
        return "carregando"
    }

    if(!auth){        
        return (<div className="google-auth-screen">
                <GoogleAuth setLoading={setLoading} setAuth={setAuth} setPermissions={setPermissions}/>
            </div>)
    }

    return  <Routes />
}

export default App;