import React from "react";
import { Flex, Box, Text,InputRightElement, Switch, Tooltip , Input, InputRightAddon, InputGroup } from "@chakra-ui/react";
import {CheckIcon} from '@chakra-ui/icons'


const JiraAccountCycle = ({cycleNumber,cycle,cycleIndex,accountCycles,setAccountCycles,isAccountClosed}) => {
    const [thisCycleHours,setThisCycleHours] = React.useState(getCycleHours())

    function toggleAdvanceTime(e){
        let newAccountCycles = JSON.parse(JSON.stringify(accountCycles));
        let time = newAccountCycles[cycleIndex+1].cycleProjectedTime/2
        if(e.target.checked){
            newAccountCycles[cycleIndex].advanceTime = time
            newAccountCycles[cycleIndex+1].advanceTime = -time
        }else{
            newAccountCycles[cycleIndex].advanceTime = 0
            newAccountCycles[cycleIndex+1].advanceTime = 0
        }
        setAccountCycles(newAccountCycles)
    }

    function getCycleHours() {
        let value =cycle?.cycleProjectedTime;
        if (value) {
            return value / 3600;
        }
        return 0;
    }

    function checkIfAdvanceIsDisabled(){
        if(isAccountClosed) return true
        if( cycleIndex !== accountCycles.length-1 && cycle?.advanceTime>=0 && accountCycles[cycleIndex+1]?.advanceTime<=0 && accountCycles[cycleIndex+1]?.cycleProjectedTime>0){
            return false
        }
        return true
    }

    function checkIfHasAdvance(){
        if(cycle?.advanceTime>0) return true
        return false
    }

    function getAdvanceTime(){
        let adv = cycle?.advanceTime;
        if(!adv) return false
        return adv>0?`+${adv/3600} Hrs`:`${adv/3600} Hrs`;
                                                        
    }

    function getStartDate(){

        let cycleStartsAt = cycle?.cycleStartsAt
        var options = { year: 'numeric', month: 'short', day: 'numeric',timeZone: 'UTC' };
        if(cycleStartsAt){
            return (new Date(cycleStartsAt)).toLocaleDateString("pt-BR", options)
        }
        return null
    }
    function changeCycleHours(e){
        let value = e.target.value
        value = Number(value)
        if (!e.target.validity.valid || isNaN(value)) return;
        setThisCycleHours(value)
    }
    function changeAccountCycleHours( e) {
        let newAccountCycles = JSON.parse(JSON.stringify(accountCycles));
        if (cycle) {
            newAccountCycles[cycleIndex].cycleProjectedTime = thisCycleHours*3600
        }
        newAccountCycles.sort((a, b) => a?.cycleNumber < b?.cycleNumber)
        setAccountCycles(newAccountCycles)
    }
    return (
        <Flex h={"127px"} borderRadius={"6px"} w={"calc(25% - 8px)"} backgroundColor={"#f3f3f3"} flexWrap={"wrap"} mr={2} p={2} pr={0} mb={2}>
            <Text fontWeight={"bold"} fontSize={"sm"} w={"100%"}>
                Ciclo {cycleNumber}
            </Text>
            <InputGroup size={"sm"} w={"100%"}>
                <Box position={"relative"} w={"56px"}>
                    <Input disabled={isAccountClosed} size={"sm"} w={"100%"} pattern="[0-9]*" background={"white"} onBlur={(e)=>changeAccountCycleHours(e)} onChange={(e) => changeCycleHours(e)} value={thisCycleHours} type="tel" placeholder="" />
                    <InputRightElement pointerEvents={"none"} w={"24px"} children={thisCycleHours?<CheckIcon color='green.500'/>:<></>} />
                </Box>
                <InputRightAddon p={2} children="hrs" />
                <Text ml={2} fontSize={"sm"} alignSelf={"center"}>
                    {getAdvanceTime()}
                </Text>
            </InputGroup>
            <Text m={2} ml={0} fontSize={"sm"}>
                Adiantamento:
            </Text>
            <Switch isChecked={checkIfHasAdvance(cycleNumber)} isDisabled={checkIfAdvanceIsDisabled(cycleNumber)} onChange={(e) => toggleAdvanceTime(e)} size={"sm"} mt={2} />
            <Tooltip fontSize='xs' label={`Inicio: ${getStartDate(cycleNumber)}`}>
                <Text isTruncated display={"block"} w={"100%"} fontSize={"sm"}>Inicio: {getStartDate(cycleNumber)}</Text>
            </Tooltip>
        </Flex>
    );
};

export default JiraAccountCycle;
