
import React from 'react'
import { Box, Table, Thead, Tbody, Th, Tr, Td, Spinner, IconButton, Select, Input, TableContainer, Flex } from '@chakra-ui/react'
import { UilTrashAlt } from '@iconscout/react-unicons'
import PlanningLogo from '../Planning/PlanningHeader/PlanningLogo';
import PopoverAddRelationUser from './PopoverAddRelationUser';
import { getRelationshipUsers, upsertUserPontoMaisJira } from '../../services/jira';
import { AuthContext } from '../../context/AuthContext';
import PontoUserDropdown from './PontoUserDropdown';
import { useToast } from '@chakra-ui/toast';
import AutoSizer from 'react-virtualized-auto-sizer';
import RelationsRow from './RelationsRow';

export default function PlannerFilters() {
    const [auth] = React.useContext(AuthContext);
    const [isLoading, setIsLoading] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [usersLoading, setUsersLoading] = React.useState(false)
    const toast = useToast();

    React.useEffect(()=>{
        setUsersLoading(true)
        getRelationshipUsers(auth.accessToken).then(res=>{
            const users = res.data.map(user=>{
                //remove prop __typename
                delete user.__typename
                return user
            })
            setUsers(users)
            setUsersLoading(false)
        })
    },[])


    function handleEmailChange(e, index) {
        let newUsers = [...users]
        let user = newUsers[index]
        user.email = e.target.value
        upsertUser(user)
            .then((res)=>{
                console.log(res)
            })

        setUsers(newUsers)
    }
    
    function handleDeleteUser(idJira) {
        let newUsers = [...users]
        let user = newUsers.find(user => user.idJira === idJira)
        user.ativo = 0
        upsertUser(user).then((res)=>{
            setUsers(newUsers)
        })
        setUsers(newUsers)
    }

    function upsertUser(user){
        let toastName = user.nomeJira.length > 25 ? user.nomeJira.substring(0,25) + '...' : user.nomeJira
       return  upsertUserPontoMaisJira(auth.accessToken,{user})
        .then((res)=>{
            toast({
                title: `Usuário ${toastName} atualizado com sucesso`,
                status: "success",
                duration: 2000,
                isClosable: true,
                position:"top"
            })
        })
        .catch((err)=>{
            toast({
                title: `Ocorreu um erro ao atualizar o usuário ${toastName}`,
                message: err.message,
                status: "error",
                duration: 2000,
                isClosable: true,
                position:"top"
            })
        })
    }

    return (
        <Box flexBasis='100%' mr={0} ml={'auto'} px={'4%'} pt={3} overflow={'auto'} >
            <Box className='container d-flex' gridGap={4}>
                <PlanningLogo />
                <div className="px-4 py-2 bg-light rounded gap-10 max-h-14 w-100" >
                    <div className='d-flex'>
                        <PopoverAddRelationUser {...{users,setUsers,upsertUser}}/>
                    </div>
                </div>
            </Box>
            <Box bg={"white"} mt={3} mb={"150px"} pb={"150px"} overflow={"visible"} background={"white"}>
                <Flex p={4} pb={1} pr={0} gridGap={2} fontWeight={"bold"} color={"#4a5566"} textTransform={"uppercase"} fontSize={"xs"} fontFamily={"Roboto"}>
                    <Box pl={1} flexBasis={"25%"}>Usuário Jira</Box>
                    <Box pl={1} flexBasis={"25%"}>Usuário Ponto</Box>
                    <Box pl={1} flexBasis={"25%"}>Email</Box>
                    <Box pl={1} flexBasis={"9%"}>Cargo</Box>
                    <Box pl={1} flexBasis={"9%"}>Departamento</Box>
                    <Box pl={1} flexBasis={"5%"}></Box>
                </Flex>
                <AutoSizer>
                    {({ height, width }) => (
                        <Box width={width} height={ height} bg={"white"} mt={3} overflow={"visible"} m>
                            {users.filter(user=>user.ativo===1).map((user, index) => (
                                <RelationsRow key={"row-"+ user.idJira} {...{index,user,users,setUsers,handleEmailChange,handleDeleteUser,upsertUser}}/>
                            ))}
                        </Box>
                    )}
                </AutoSizer>
            </Box>
            {/* <Box bg={"white"} mt={3} mb={3} pb={"150px"} overflow={"visible"}>
                <TableContainer overflow={"visible"}>
                    <Table variant='simple' overflow={"visible"}>
                        <Thead>
                            <Tr>
                                <Th px={1}>
                                    Usuário Jira
                                </Th>
                                <Th px={1}>
                                    Usuário Ponto
                                </Th>
                                <Th px={1}>
                                    Email
                                </Th>
                                <Th px={1}>
                                    Cargo
                                </Th>
                                <Th px={1}>
                                    Departamento
                                </Th>
                                <Th px={1}>

                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody overflow={"visible"}>
                            {isLoading ?
                                <Tr>
                                    <Td colSpan={2} textAlign="center">
                                        <Spinner size="xl" />
                                    </Td>
                                </Tr>
                                : users.filter(user=>user.ativo===1).map((user, index) => (
                                    <Tr key={user?.idJira}>
                                        <Td px={1}>{user?.nomeJira}</Td>
                                        <Td px={1} py={0}>
                                            <PontoUserDropdown {...{users,setUsers,idJira:user.idJira,thisUser:user,upsertUser}}/>
                                        </Td>
                                        <Td px={1}>
                                            <Input defaultValue={user?.email} onBlur={(e) => handleEmailChange(e, index)} />
                                        </Td>
                                        <Td px={1}>
                                            {user?.cargo}
                                        </Td>
                                        <Td px={1}>
                                            {user?.departamento}
                                        </Td>
                                        <Td px={1} maxW={'10%'}>
                                            <IconButton onClick={() => handleDeleteUser(user.idJira)} icon={<UilTrashAlt size={16} />} />
                                        </Td>
                                    </Tr>
                                ))}

                        </Tbody>
                    </Table>
                </TableContainer>
            </Box > */}
        </Box >

    )
}
