
import React from 'react'

const UserFilterContext = React.createContext();

const UserFilterProvider = ({ children }) => {
    const [usersToShow, setUsersToShow] = React.useState([]);
    const [loadingUsersToShow,setLoadingUsersToShow] = React.useState(false)

    return (
        <UserFilterContext.Provider value={[usersToShow, setUsersToShow,loadingUsersToShow,setLoadingUsersToShow]}>
            {children}
        </UserFilterContext.Provider>
    );
}

export { UserFilterContext, UserFilterProvider };
