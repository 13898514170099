import React, { useState, useContext, useEffect } from 'react'
import { DateRangeContext } from './DateRangeContext'
import { searchIssues } from '../../../services/jira';
import convertDecimalToTime from '../utils/convertDecimalToTime'
import normalizeDate from '../utils/normalizeDate'
import { AuthContext } from '../../../context/AuthContext';
const UserTasks = React.createContext();

const UserTasksProvider = ({ children }) => {
  // const [tasks, setTasks] = React.useState(false);
  const [issues, setIssues] = useState([])
  const [dateRange] = useContext(DateRangeContext)
  const [auth] = React.useContext(AuthContext);

  function countWeekdays(startDate, endDate, flag) {
    let currentDate = new Date(startDate);
    let targetDate = new Date(endDate);
    let weekdays = 0;

    while (currentDate <= targetDate) {
      if(flag){

      }
     const dayOfWeek = currentDate.getDay();
      if (dayOfWeek >= 1 && dayOfWeek <= 5) { // Monday to Friday are weekdays
        weekdays++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return weekdays;
  }

  function taskReq(employeeId) {
    return new Promise((resolve, reject) => {
      searchIssues(auth.accessToken, `assignee%20in%20(${employeeId})%20and%20status%20!%3D%20%22Done%22%20and%20status%20!%3D%20%22Conclu%C3%ADdo%22%20and%20status%20!%3D%20%22Valida%C3%A7%C3%A3o%20Cliente%22`, auth.accessToken).then((response) => {
        if (response?.data?.issues.length > 0) {
          setIssues(response.data.issues)
        }
        else {
          setIssues([])
        }
        resolve()
      }).catch(e => reject(e))
    })
  }

  function filterIssuesByStatus(issue) {
    return !(issue.fields.status.id == '10004' || issue.fields.status.id == '10003' || issue.fields.status.id == "1" || issue.fields.status.id == "10019")
  }

  function updateIssue(issueKey, startDate, endDate, timeoriginalestimate) {
    const newIssues = [...issues]
    const index = newIssues.findIndex(issue => issue.key === issueKey)
    newIssues[index].fields.customfield_10022 = startDate || newIssues[index].fields.customfield_10022
    newIssues[index].fields.customfield_10023 = endDate || newIssues[index].fields.customfield_10023
    newIssues[index].fields.timeoriginalestimate = timeoriginalestimate || newIssues[index].fields.timeoriginalestimate
    const sd = normalizeDate(newIssues[index].fields.customfield_10022)
    const fd = normalizeDate(newIssues[index].fields.customfield_10023)

    const weekdays = countWeekdays(sd, fd);
    const hoursPerDay = convertDecimalToTime((newIssues[index].fields.timeoriginalestimate / 3600) / weekdays || 1)
    const hoursPerDayDecimal = (newIssues[index].fields.timeoriginalestimate / 3600) / weekdays || 1
    newIssues[index].hoursPerDay = hoursPerDay
    newIssues[index].hoursPerDayDecimal = hoursPerDayDecimal
    setIssues(newIssues)
  }

  const treatedIssues = issues.map(issue => {
    if (!issue.fields.customfield_10022 || !issue.fields.customfield_10023) return;

    const startDate = normalizeDate(issue.fields.customfield_10022)
    const finalDate = normalizeDate(issue.fields.customfield_10023)
    let weekdays = countWeekdays(startDate, finalDate)

    let hoursPerDay = convertDecimalToTime((issue.fields.timeoriginalestimate / 3600) / weekdays)
    let hoursPerDayDecimal = (issue.fields.timeoriginalestimate / 3600) / weekdays;
    //check if it is status.id 10022
    const willDeploy = issue.fields.status.id === '10022';
    let willDeployDate = null
    if(willDeploy){
      let deployDate = issue.fields?.customfield_10189
      //if theres no deploydate use enddate but +1d
      console.log(finalDate)
      if(!deployDate){
        deployDate = new Date(finalDate)
        deployDate.setDate(deployDate.getDate()+1)
      } 

      willDeployDate = new Date(deployDate)
      let normalizedDate = willDeployDate.toISOString().split("T")[0]
      issue.fields.customfield_10023 = normalizedDate;
      issue.fields.customfield_10022 = normalizedDate;
      hoursPerDay = convertDecimalToTime(1)
      hoursPerDayDecimal = 1
      weekdays =1
    }


    return { ...issue, hoursPerDay, hoursPerDayDecimal,willDeployDate}
  })
  
  const filteredIssues = treatedIssues.filter(issue => {
      if (!issue?.fields?.customfield_10022 || !issue?.fields?.customfield_10023) return;
      const startDate = normalizeDate(issue.fields.customfield_10022)
      const finalDate = normalizeDate(issue.fields.customfield_10023)
      return (startDate <= new Date(dateRange[1]).setHours(0, 0, 0, 0) && finalDate >= new Date(dateRange[0]).setHours(0, 0, 0, 0))
    }).filter(filterIssuesByStatus)
    //sort by  type, just group them by issue.fields.status.id but prioritize id 10022
    .sort((a,b)=>{
        if(a.fields.status.id==='10022') return -1
        if(b.fields.status.id==='10022') return 1
        if(a.fields.status.id>b.fields.status.id) return 1
        if(a.fields.status.id<b.fields.status.id) return -1
        return 0
    })
  //removendo issues com certos status
  const noDateIssues = issues.filter(issue => {
    return !issue.fields.customfield_10022 || !issue.fields.customfield_10023;
  }).filter(filterIssuesByStatus)

  return (
    <UserTasks.Provider value={[issues, setIssues, filteredIssues, noDateIssues, taskReq, countWeekdays, updateIssue]}>
      {children}
    </UserTasks.Provider>
  );
}

export { UserTasks, UserTasksProvider };