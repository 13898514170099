import { Box, Flex, IconButton, Input, Tooltip } from '@chakra-ui/react'
import { UilTrashAlt } from '@iconscout/react-unicons'
import React from 'react'
import PontoUserDropdown from './PontoUserDropdown'

const RelationsRow = ({index,user,users,setUsers,handleEmailChange,handleDeleteUser,upsertUser}) => {
  return (
    <Flex  alignItems={"center"} width={"100%"} pl={4} gridGap={2} background={"white"} borderTop={"1px solid #dfdfdf"}>
        <Tooltip isDisabled={user?.nomeJira?.length<24?true:false} label={user?.nomeJira} placement="top" hasArrow>
            <Box flexBasis={"25%"} py={2} px={1} isTruncated>{user?.nomeJira}</Box>
        </Tooltip>
        <Box flexBasis={"25%"} py={2} px={1}>
            <PontoUserDropdown {...{users,setUsers,idJira:user.idJira,thisUser:user,upsertUser}}/>
        </Box>
        <Box flexBasis={"25%"} py={2} px={1}>
            <Input defaultValue={user?.email} onBlur={(e) => handleEmailChange(e, index)} />
        </Box>
        <Box isTruncated flexBasis={"9%"} py={2} px={1} >
            {user?.cargo}
        </Box>
        <Box isTruncated flexBasis={"9%"} py={2} px={1}>
            {user?.departamento}
        </Box>
        <Box flexBasis={"7%"} py={2} px={1} maxW={'10%'}>
            <IconButton onClick={() => handleDeleteUser(user.idJira)} icon={<UilTrashAlt size={16} />} />
        </Box>
    </Flex>
  )
}

export default RelationsRow