import { Avatar, Box, Button, Checkbox, Flex, FormControl, IconButton, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Select, Spinner, Stack, Text } from '@chakra-ui/react'
import { UilTrashAlt } from '@iconscout/react-unicons'
import React from 'react'
import {  searchUsers as su } from '../../services/jira'
import { AuthContext } from '../../context/AuthContext'
import _ from 'lodash'


const PopoverAddRelationUser = ({ users, setUsers,upsertUser }) => {
    const [jiraUsers, setJiraUsers] = React.useState([])
    const [selectedUserList, setSelectedUserList] = React.useState([])
    const [userListToShow,setUserListToShow] = React.useState(jiraUsers)
    const [auth] = React.useContext(AuthContext);
    const [userFilter,setUserFilter] = React.useState("")
    const [userReqIsLoading,setUserReqIsLoading] = React.useState(false)
    const [apiCalled,setApiCalled] = React.useState(false)
    const debounceFilterUsers = React.useCallback(_.debounce(filterUsers, 500), []);
    
    React.useEffect(() => {
        if(jiraUsers.length){
            let newList = jiraUsers.filter(user => !selectedUserList.find(selectedUser => selectedUser.idJira === user.idJira))
            //filter jira users that are already in users
            newList = newList.filter(user => !users.find(userInUsers => userInUsers.ativo === 1 && userInUsers.idJira === user.idJira))
            setUserListToShow(newList)
        }
    }, [jiraUsers,selectedUserList])
      
    function addUser(userId){
        let user = jiraUsers.find(user => user.idJira === userId)   
        //delete avatar
        delete user.avatar
        setSelectedUserList([...selectedUserList, user])
    }

    function saveChanges(){
        //add user
        //add to start of the array
        let usersToAddToDb = selectedUserList.map(user => {
            //find if user is already in users
            let userInUsers = users.find(userInUsers => userInUsers.idJira === user.idJira)
            if(userInUsers){
                userInUsers.ativo = 1
                return userInUsers
            }else{
                return user
            }
        })
        setUsers([...selectedUserList,...users])
        setSelectedUserList([])
        setJiraUsers([])
        setUserListToShow([])
        setUserFilter("")
        //no need to wait for the response
        usersToAddToDb.forEach(user => {
            upsertUser(user)
        })
    }

    function onClose(){
        setSelectedUserList([])
    }

    function handleDeleteUser(index){
        let newList = [...selectedUserList]
        newList.splice(index,1)
        setSelectedUserList(newList)
    }

    
    function userInputOnChange(e) {
        const value = e.target.value
        debounceFilterUsers(value)
        setUserFilter(value)
    }

    function filterUsers(value) {

        if (value.length > 2) {
            //fetch users here
            setUserReqIsLoading(true)
            su(auth.accessToken,value).then((response) => {
                const filteredUsers = response.data.filter(user => user.accountType === "atlassian" && user.active===true)
                const users = filteredUsers.map(user => {
                    return {
                        idJira: user.accountId,
                        nomeJira: user.displayName,
                        avatar: user.avatarUrls["48x48"],
                        ativo:user.active?1:0,
                        email:user.emailAddress,
                    }
                })
                setJiraUsers(users)
                setApiCalled(true)
            }).catch(err=>{
                console.log(err)
            }).finally(()=>{
                setUserReqIsLoading(false)
            })
        }
    }

    return (
        <Popover onClose={onClose} closeOnBlur={true}>
                  {({ isOpen, onClose }) => (
                    <>
            <PopoverTrigger>
                <Button>Adicionar Usuário</Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Adicionar Usuário</PopoverHeader>
                <PopoverBody>
                    <Flex flexDir={"column"}>
                        {/* <Box className="form-group">
                            <Select className="form-control" id="jiraUser" onChange={(e) => { addUser(e) }}>
                                <option>Selecione um usuário</option>
                                {userListToShow.map((user) => <option key={user.idJira} value={user.idJira}>{user.nomeJira}</option>)}
                            </Select>

                        </Box> */}
                        <FormControl>
                            <Input placeholder='Digite o nome do usuário' value={userFilter} onChange={(e)=>userInputOnChange(e)} />
                            <Stack mt={3} maxH={150} overflow={"auto"}>
                                {userReqIsLoading && <Spinner/>}
                                {!userReqIsLoading && userListToShow.map((user) => 
                                    <Flex onClick={(e)=>addUser(user.idJira)} gridGap={2} alignItems={"center"} key={user.idJira} _hover={{"cursor":"pointer"}}>
                                        <Checkbox size='md' name={user.nomeJira} src={user.avatar}></Checkbox>
                                        <Box>
                                            {user.nomeJira}
                                        </Box>
                                    </Flex>
                                )}
                                {!userReqIsLoading && apiCalled && !userListToShow.length && <Text>Nenhum usuário encontrado</Text>}      
                            </Stack>
                        </FormControl>
                        <Stack mt={4} gridGap={2} maxH={250} overflow={"auto"} pr={1}>
                            {selectedUserList.map((user,index) => <Flex justifyContent={"flex-start"} gridGap={2} alignItems={"center"} key={user.idJira}>
                                <Avatar size='sm' name={user.nomeJira} src={user.avatar}></Avatar>
                                <Box>
                                    {user.nomeJira}
                                </Box>
                                <IconButton ml="auto" size="sm" colorScheme='red' onClick={() => handleDeleteUser(index)} icon={<UilTrashAlt size={16} />} />
                            </Flex>)}
                        </Stack>
                        <Box mt={3}>
                            <Button onClick={()=>{saveChanges();onClose()}}>Salvar</Button>
                        </Box>
                    </Flex>
                </PopoverBody>
            </PopoverContent>
            </>
            )}
        </Popover>


    )
}

export default PopoverAddRelationUser