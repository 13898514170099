import React from "react"
import { DateRangeContext } from "./context/DateRangeContext"
import { Flex, Heading } from "@chakra-ui/react"
import { AuthContext } from "../../context/AuthContext"
import { UserFilterContext } from "../Planning/context/UserFilterContext"
import PerformanceRow from "./details/PerformanceRow"
import { getRelationshipUsers } from "../../services/jira"

export function percentageToText(percentage) {
    //only two decimal places if theres any
    //parameter is a float number
    if (percentage) {
        return `${(percentage*100).toFixed(2)}%`
    }
    return '0%'
}

export function secondsToHours(seconds) {
    if(!seconds) return '00:00:00'
    //format to 00:00:00
    let hours = Math.floor(seconds / 3600)
    let minutes = Math.floor((seconds - (hours * 3600)) / 60)
    let remainingSeconds = Math.floor(seconds - (hours * 3600) - (minutes * 60))
    if (hours < 10) { hours = "0" + hours }
    if (minutes < 10) { minutes = "0" + minutes }
    if (remainingSeconds < 10) { remainingSeconds = "0" + remainingSeconds }
    return `${hours}:${minutes}:${remainingSeconds}`
}


export default function PerformanceContent() {
    const [[startDate,endDate]] = React.useContext(DateRangeContext)
    const [usersToShow,_,loadingUsersToShow] = React.useContext(UserFilterContext)
    const [relationshipLoading,setRelationshipLoading] = React.useState(false)
    const [auth] = React.useContext(AuthContext)
    const [jiraTempoRelationship,setJiraTempoRelationship] = React.useState([])

    React.useEffect(()=>{
        setRelationshipLoading(true)
        getRelationshipUsers(auth.accessToken).then(res=>{
            setRelationshipLoading(false)
            setJiraTempoRelationship(res.data)
        })
    },[auth])

    
    function getPontoUserId(userId){
        return jiraTempoRelationship.find(item=>item.idJira===userId)?.idPontoMais
    }

    React.useEffect(()=>{
        console.log(usersToShow)
        console.log(relationshipLoading)
        console.log(loadingUsersToShow)
    },[usersToShow])

    return (
        <Flex flexDir={"column"} sx={{'gap':'12px'}} w={'100%'} flexWrap={"wrap"}>
            {!relationshipLoading && !loadingUsersToShow && !usersToShow.length && <Heading m={"0 auto"} bg={"transparent"} as={"h5"} fontSize={"18px"}> Nenhum resultado encontrado</Heading> }
            {!relationshipLoading && !loadingUsersToShow && usersToShow?.map((item,i) => (
                <PerformanceRow  key={`performance-data-${item.id}`}  {...{internalIsLoading:relationshipLoading,from:startDate,to:endDate,userId:item.id,pontoUserId:getPontoUserId(item.id),name:item.name}}/>
            ))}
        </Flex>
    )
}