//code to check if the user has scrolled the ref element to the right at any amount

import React from "react"

export default function ScrollCheck({ containerRef,headerRef }) {

    React.useEffect(() => {
        if (containerRef?.current && headerRef?.current) {
            const handleScroll = (e) => {
                //add style top to headerref with the value of scrolltop of containerRef
                headerRef.current.style.top = `${e.target.scrollTop}px`
            }

            containerRef?.current?.addEventListener('scroll', handleScroll)
            return () => {
                containerRef?.current?.removeEventListener('scroll', handleScroll)
            }
        }
    }, [containerRef,headerRef])

    return (
        <></>
    )
}