import React, { useContext } from 'react'
import TimeSelector from './TimeSelector'
import TooltipComponent from './TooltipComponent'
import { DateRangeContext } from '../context/DateRangeContext';
import normalizeDate from '../utils/normalizeDate';


function getMessage(task){
  if(task.willDeployDate){
    return "O deploy irá ser executado no dia indicado. Tarefas aprovadas para deploy não podem ser alteradas"
  }
  return "A tarefa inicia ou termina fora do período selecionado, alguns dias foram ocultos da visualização"
}

export default function TaskRow({ task, noDate, index,disableEdit}) {
  const [showToolTip, setShowToolTip] = React.useState(false)
  const [dateValues] = useContext(DateRangeContext);
  React.useEffect(() => {
    if(task.willDeployDate){
      setShowToolTip(true)
      return
    }
    if (task?.fields?.customfield_10022 && task?.fields?.customfield_10023) {
      const initialDate = task.fields.customfield_10022
      const finalDate = task.fields.customfield_10023
      if ((new Date(dateValues[0])).setHours(0, 0, 0, 0) > new Date(normalizeDate(initialDate)) || (new Date(dateValues[1])).setHours(0, 0, 0, 0) < new Date(normalizeDate(finalDate))) {
        setShowToolTip(true)
      }
      else setShowToolTip(false)
    }
  }, [dateValues, task])

  return (
    <div className='taskRow m-0 border-bottom border-gray400 d-flex'>
      <div className='leftColContainer'>
      <div className='leftCol pr-0'>
        <div className='taskSummary'> <a href={`https://maeztra.atlassian.net/browse/${task?.key}`} target='_blank'><span className=' text-gray600'>{task?.key}</span></a> <span className='summary text-gray800 fw-bolder' title={task.fields.summary}>{task.fields.summary}</span></div>
        <div className='text-right d-flex justify-content-end align-items-center'><span className={`${task.fields.status.name.toLowerCase().replace(' ', '-')} statusFlag`}>{task.fields.status.name}</span></div>
      </div>
      {showToolTip? <TooltipComponent text={getMessage(task)}/>:<div className="spacer"></div>}
      </div>
      <div className='text-center w-100'><TimeSelector disableEdit={disableEdit} task={task} index={index} noDate={noDate} /></div>
    </div>
  )
}
