
import React from 'react'
import { Row, Col } from 'react-bootstrap'

import PlanningLogo from '../Planning/PlanningHeader/PlanningLogo';

import DateSelector from './PerformanceHeader/DateSelector';
import FilterSelector from '../Planning/PlanningHeader/FilterSelector';

export default function PlannerFilters() {
    return (
        <div className='container d-flex'>
            <PlanningLogo/>
            <div className="px-4 py-2 bg-light rounded gap-10 max-h-14 w-100" >
                <div className='d-flex'>
                    <FilterSelector/>
                    <DateSelector/>
                </div>
            </div>
        </div>
    )
}
