import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import Home from './components/Home/Home';
import PermissionManagement from './components/PermissionManagement/PermissionManagement'
import Planning from './components/Planning/Planning';
import PageWithMenu from './components/Page/PageWithMenu';
import JiraAccount from './components/JiraAccount/JiraAccount';
import PerformanceDashboard from './components/Performance/PerformanceDashboard';
import RelationsManagement from './components/RelationsManagement/RelationsManagement';


export default function Routes() {

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact>
                    <PageWithMenu activeLink={"/"} props={<Home />} useChakra={true} />
                </Route>
                <Route path='/jira-accounts/'>
                    <PageWithMenu activeLink={"/jira-accounts/"} props={<JiraAccount />} useChakra={true} />
                </Route>
                <Route path="/permission-management/management">
                    <PageWithMenu activeLink={"/permission-management/"} props={<PermissionManagement />} useChakra={true}></PageWithMenu>
                </Route>
                <Route path="/permission-management/*">
                    <Redirect from='*' to='/permission-management/management' />
                </Route>
                <Route path="/performance" exact>
                    <PageWithMenu activeLink={"/performance/"} useChakra={true} props={<PerformanceDashboard />}></PageWithMenu>
                </Route>

                <Route path="/planning" exact>
                    <PageWithMenu activeLink={"/planning/"} props={<Planning />}></PageWithMenu>
                </Route>
                <Route path="/userRelations" exact>
                    <PageWithMenu activeLink={"/userRelations"} useChakra={true} props={<RelationsManagement />}></PageWithMenu>
                </Route>
                <Redirect from='*' to='/' />
            </Switch>
        </BrowserRouter>

    )
}