import React from 'react'
import { useContext } from 'react';
import { Col, Form } from 'react-bootstrap'
import { GroupingTypeContext } from '../context/GroupingTypeContext';

const GroupingType = () => {
    const [groupingType, setGroupingType] = useContext(GroupingTypeContext);
  return (
    <Col className="d-flex flex-row gap-4 col-3 align-items-center" >
        <div class="text-gray-700 fw-semibold ">
            Visão
        </div>
        <div>
            <Form className="d-flex flex-row custom-radio"  onChange={(e)=>setGroupingType(e.target.value)}>
                <Form.Check checked={groupingType==="days"} value="days" inline  type={"radio"} label={"Dias"} name={"view"} id={"days"} />
                <Form.Check checked={groupingType==="weeks"} value="weeks" inline className="mr-0" type={"radio"} label={"Semanas"} name={"view"} id={"week"} />
            </Form>
        </div>
    </Col>
  )
}

export default GroupingType