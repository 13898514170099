import { useState,useEffect,useContext } from 'react'
import { useToast,FormErrorMessage,Button,FormControl, FormLabel, Input, Flex, Box, Container,Modal, ModalOverlay,  ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton} from "@chakra-ui/react"
import { AuthContext } from '../../context/AuthContext';
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import api from '../../services/authApi'

const schema = yup.object().shape({
    name: yup.string().required('Nome da permissão é obrigatório'),
})

const toastPermissionAdded = {
    title: "Permissão adicionada.",
    status: "success",
    duration: 2000,
    isClosable: true,
    position:"top"
}
const toastPermissionError = {
    title: "Ocorreu um erro ao adicionar a permissão.",
    status: "error",
    duration: 2000,
    isClosable: true,
    position:"top"
}

const NewPermission = ({options,setOptions,isOpenNewPermission,onCloseNewPermission}) => {
    const toast = useToast()
    const [auth, setAuth] = useContext(AuthContext);
    const [btnSendIsLoading,setBtnSendIsLoading] = useState(false)
    const { register, handleSubmit,reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    useEffect(()=>{
        if(auth){
            api.defaults.headers.common['google-token'] = auth
        }
    },[auth])

    const onSubmit = async (data) => {
        try {
            setBtnSendIsLoading(true)
            api.post('/createPermission', data).then((res)=>{
                let newPermissions = [...options]
                newPermissions.unshift({
                    id:res.data.result.insertId,
                    name:data.name,
                })
                setOptions(newPermissions)
                onCloseNewPermission()
                reset()
                toast(toastPermissionAdded)
            }).catch((err)=>{
                toast(toastPermissionError)
                if(err.message.error){
                    setAuth(null)
                }
            }).finally(()=>{
                setBtnSendIsLoading(false)
            })

        } catch (error) {
            console.error(error)
        }
    }

    const errorProperties = (errors) => !!errors && {
        isInvalid: true,
        errorBorderColor: "crimson"
    }
    

    return (
        <Modal isOpen={isOpenNewPermission} onClose={onCloseNewPermission} >
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>Adicionar usuário</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <Flex direction='row' h={'100%'} fontFamily={'Roboto'}>
                    <Box flexBasis='100%' pt={3} overflow={'auto'}>
                        <Container maxW="100%" bgColor="white" p={0}>                    
                            <form method="POST" onSubmit={handleSubmit(onSubmit)}>        
                                <FormControl id="register" d={'flex'} flexWrap="wrap" pb={4}>
                                    <Box flexBasis={'100%'} pb={4}>
                                        <FormLabel htmlFor="name">Nome da permissão</FormLabel>
                                        <Input
                                            type="text" 
                                            focusBorderColor="initial"
                                            {...register('name')}
                                            {...errorProperties(errors.name)}
                                        />
                                        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                                    </Box>
                                </FormControl>                                
                            </form>
                        </Container>
                    </Box>
                </Flex>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={onCloseNewPermission}>Fechar</Button>
                    <Button colorScheme={"yellow"} isLoading={btnSendIsLoading} onClick={handleSubmit(onSubmit)} type="submit">Enviar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default NewPermission

