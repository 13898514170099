export function formatDate(date) { //data formatada igual no layout
    const daysOfWeek = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];
    const year = date.getFullYear();
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;
  
    return {formatedDate:`${dayOfWeek} ${formattedDay}/${formattedMonth}`,dateDataBase:`${year}-${formattedMonth}-${formattedDay}`};
  }