
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
    Tooltip,
} from '@chakra-ui/react'
import React from 'react';
import { secondsToHours } from '../PerformanceContent'

function toBrazilianDateString(dateString) {
    const date = new Date(dateString);
    const options = { timeZone: 'UTC', year: 'numeric', month: '2-digit', day: '2-digit' };
    const brDateStr = date.toLocaleDateString('pt-BR', options);
    return brDateStr;
}

function getLocaleDayOfWeek(dateString){
    const date = new Date(dateString);
    const options = { timeZone: 'UTC', weekday: 'long' };
    const dayOfWeek = date.toLocaleDateString('pt-BR', options);
    return dayOfWeek;
}

export default function TotalHoursDetails({worklogsByDate,internalWorklogs}) {
    const [data] = React.useState(Object.keys(worklogsByDate).map(dateString=>{
        return {
            ...worklogsByDate[dateString],
            date:dateString
        }
    }))

    return    <Box maxH={'350px'} overflow={'auto'}>
        <TableContainer>
        <Table variant='striped' >
            <Thead>
                <Tr>
                    <Th>Data</Th>
                    <Th>HRs Cliente</Th>
                    <Th>HRs Internas</Th>
                    <Th>HRs Totais</Th>
                    <Th>Tempo PontoMais</Th>
                </Tr>
            </Thead>
            <Tbody>
                {data.map((item, index) => (
                    <Tr key={`totalHours-$${item.date}-${index}`}>
                        <Td py={2}>
                            {/* <Tooltip placement={"top"} label={getLocaleDayOfWeek(item.date)}> */}
                                {`${toBrazilianDateString(item.date)} - ${getLocaleDayOfWeek(item.date)}`}
                            {/* </Tooltip> */}
                        </Td>
                        <Td py={2}>{secondsToHours(item.billableTime)}</Td>
                        <Td py={2}>{secondsToHours(internalWorklogs[item.date] || 0)}</Td>
                        <Td py={2}>{secondsToHours(item.time)}</Td>
                        <Td py={2}>{secondsToHours(item.pontoTime)}</Td>
                    </Tr>
                ))}
            </Tbody>
        </Table>
    </TableContainer>
    </Box>

}