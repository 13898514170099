import React from 'react'
import { Select } from '@chakra-ui/react'
import { getJiraSquadsByAccountKey } from '../../services/jira';
import { AuthContext } from '../../context/AuthContext';

const JiraSquadSelect = ({squadKey, setSquadKey,setSquadName,accountKey}) => {
    const [auth] = React.useContext(AuthContext)
    const [squadList,setSquadList] = React.useState([])
    const [noSquadFound,setNoSquadFound] = React.useState(false)

    React.useEffect(()=>{
        getJiraSquadsByAccountKey(auth.accessToken,accountKey)
            .then((resp)=>{
                const squads = resp.data
                //if squadKey not in squads, set to first squad
                if(squads.length && !squads.find(squad=>squad.key===squadKey)){
                    setSquadKey(squads[0].key)
                    setSquadName(squads[0].name)
                }
                if(!squads.length){
                    setNoSquadFound(true)
                }else
                setSquadList(squads)
            })
    },[accountKey])

    function handleChange(e){
        setSquadKey(e.target.value)
        setSquadName(e.target.selectedOptions[0].text)
    }

    return (
        <Select onChange={handleChange} value={noSquadFound?"Nenhum squad encontrado":squadKey} disabled={squadList.length?false:true} size='sm' width={"180px"}>
            {noSquadFound?<option>Nenhum squad encontrado</option>:null}
            {squadList.map((squad)=><option key={squad.key} value={squad.key}>{squad.name}</option>)}
        </Select>
    )
}

export default JiraSquadSelect