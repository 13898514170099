import React from 'react'
import { Flex,Button} from "@chakra-ui/react";
import { UilBan } from "@iconscout/react-unicons";
// import { accountReport } from "../../services/jira";
// import { AuthContext } from "../../context/AuthContext";

const y300 = { bg: "yellow.300", borderColor: "yellow.300" };
const y400 = { bg: "yellow.400", borderColor: "yellow.400" };

const AccountCloseProject = ({onOpen,accountName,accountKey,setIsAccountClosed,setModalProps}) => {
    const [isActive,setIsActive] = React.useState(false)

    function handleClick(){
        onOpen()
        setModalProps({
            accountName,
            accountKey,
            setIsAccountClosed
        })
    }
    return (
        <Flex mx={0} py={1} alignItems={"flex-end"} d={"flex"} alignSelf={"flex-end"}>
            <Button disabled={isActive} size={"sm"} onClick={handleClick} _hover={y300}_active={y400}>Encerrar Projeto</Button>
        </Flex>
    )
}

export default AccountCloseProject