import axios from 'axios';

const baseUrl = process.env.NODE_ENV == "development" ? '//localhost:3000' : 'https://robocop.maeztra.com'
const token = '5b4003d1d7b31b6bada966e4ea302c79a8e0cf529ad2621cd3cf07a715bb1580'

const getJiraSquadsByAccountKey = (auth, accountKey) => {
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
    }
    //rodando robocop nessa porta
    return axios.get(`${baseUrl}/jira/getSquadsByKey/${accountKey}`, options)
}

const getJiraProject = (auth, accountId) => {

    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
    }
    //rodando robocop nessa porta
    return axios.get(`${baseUrl}/jira/getAccount/${accountId}?token=${token}`, options)
}

const getAllJiraProjects = (auth) => {
    
        let options = {
            headers: {
                "Content-Type": "application/json",
                "google-token": auth
            },
        }
        //rodando robocop nessa porta
        return axios.get(`${baseUrl}/jira/getAccounts`, options)
}

const patchJiraProject = (auth,data) => {
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "POST",
        data
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/patchAccount?token=${token}`, options)
}

const sendWebhook = (auth,data) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "POST",
        data
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/webhooks`, options)
}

const accountReport = (auth,accountId,key) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/reportUrl/${accountId}?key=${key}`, options)
}

const closeAccount = (auth,accountKey,closedDate) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "POST",
        data:{
            accountKey,
            closedDate
        }
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/closeAccount/`, options)
}

const searchIssues = (auth,jql) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/issue/search?jql=${jql ?? ""}`, options)
}

const searchUsers = (auth,query) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/user/search?query=${query ?? ""}&maxResults=20`, options)
}

const searchProject = (auth,query) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/project/search?query=${query ?? ""}`, options)
}

const getRoles = (auth) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/roles/all`, options)

}

const getAllTeams = (auth) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/teams/all`, options)
}

const getTeamMembers = (auth,teamId) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    
    return axios(`${baseUrl}/jira/teams/${teamId}`, options)

}

const getBulkUsers = (auth,usersAccountId) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    const qs = usersAccountId.map((userAccountId) => `accountId=${userAccountId}`).join('&')
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/user/bulk?${qs}`, options)
}
const patchIssueJira = (auth,data,issueKeyOrId) => {
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "PATCH",
        data
    }
    //rodando robocop nessa porta
    return axios(`${baseUrl}/jira/patchIssue/${issueKeyOrId}?token=${token}`, options)
}

const getRelationshipUsers = (auth) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }

    return axios(`${baseUrl}/jira/getJiraPontoRelationship`, options)
}

const searchPontoUsers = (auth,query) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }

    return axios(`${baseUrl}/jira/searchPontoUsers?query=${query ?? ""}`, options)
}   

const upsertUserPontoMaisJira = (auth,data)=>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "POST",
        data
    }

    return axios(`${baseUrl}/jira/upsertUserPontoMaisJira`, options)
}

export { upsertUserPontoMaisJira,searchPontoUsers,getRelationshipUsers,getJiraSquadsByAccountKey,getAllJiraProjects,getBulkUsers,getTeamMembers,getJiraProject,patchJiraProject,sendWebhook,accountReport,closeAccount,searchIssues,searchUsers,searchProject,getRoles,getAllTeams,patchIssueJira};