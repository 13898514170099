import { Flex, Heading, Skeleton, Text, Tooltip } from '@chakra-ui/react'
import { secondsToHours, percentageToText } from '../PerformanceContent'
import TotalHoursDetails from "./TotalHoursDetails"
import QAPercentageDetails from "./QAPercentageDetails"
import AssertivityDetails from "./AssertivityDetails"

import React from 'react'
import { getWorklogsByUserId } from '../../../services/performance'
import { AuthContext } from '../../../context/AuthContext'

const PerformanceRow = ({ internalIsLoading, pontoUserId, from, to, userId, name }) => {
    const [auth] = React.useContext(AuthContext)
    const [openDetails, setOpenDetails] = React.useState({
        type: ''
    })
    const [loading, setLoading] = React.useState(false)
    const [userData, setUserData] = React.useState({})
    const [totalTime, setTotalTime] = React.useState(0)

    function handleOpenDetails(type) {
        if (openDetails.type === type) {
            setOpenDetails({ type: '' })
        } else {
            setOpenDetails({ type })
        }
    }

    function getBackground(type) {
        if (openDetails.type === type) {
            return 'gray.300'
        }
        return 'white'
    }

    React.useEffect(() => {
        if (!from || !to || !userId || !auth || !pontoUserId) return
        setLoading(true)
        const fromString = (new Date(from)).toISOString().split('T')[0]
        const toString = (new Date(to)).toISOString().split('T')[0]
        getWorklogsByUserId(auth.accessToken, { from: fromString, to: toString, userId, pontoUserId }).then(res => {
            setUserData(res.data?.userConsolidatedInfo)
            setTotalTime(res.data?.userConsolidatedInfo?.totalTime)
            setLoading(false)
        })
    }, [from, to])


    return (
        <>
            {loading || internalIsLoading ?
                <Flex borderRadius={4} w={"100%"}>
                    <Skeleton w={"100%"} h={'72px'}></Skeleton>
                </Flex> :
                <Flex flexDir={'column'} bg={'white'} borderRadius={4} w={"100%"} p={2} textAlign={"left"}>
                    <Flex justifyContent={"flex-start"}>
                        <Flex flexDir={'column'} ml={3} fontSize={"xs"} isTruncated color={"gray.500"} p={2} flexBasis={"28%"}>
                            Nome
                            <Heading m={"0 auto 0 0"} bg={"transparent"} as={"h5"} fontSize={"18px"}>
                                {name}
                            </Heading>
                        </Flex>
                        {pontoUserId ? <>
                            <Flex flexDir={'column'} ml={3} fontSize={"xs"} isTruncated color={"gray.500"} borderRadius={4} p={2} flexBasis={"12%"} >
                                Horas Ponto
                                <Heading m={"0 auto 0 0"} bg={"transparent"} as={"h5"} fontSize={"18px"}>
                                    {secondsToHours(userData?.totalHoursPonto / (userData?.businessDays || 1))}
                                </Heading>
                            </Flex>
                            <Flex flexDir={'column'} ml={3} fontSize={"xs"} isTruncated color={"gray.500"} borderRadius={4} p={2} cursor={'pointer'} flexBasis={"12%"} bg={getBackground('totalHrs')} onClick={() => handleOpenDetails('totalHrs')}>
                                Horas Fat.
                                <Heading m={"0 auto 0 0"} bg={"transparent"} as={"h5"} fontSize={"18px"}>
                                    {secondsToHours(userData?.totalBillableTime / (userData?.businessDays || 1))}
                                </Heading>
                            </Flex>
                            <Flex flexDir={'column'} ml={3} fontSize={"xs"} isTruncated color={"gray.500"} borderRadius={4} p={2} flexBasis={"12%"}>
                                % Ponto/Fat.
                                <Heading m={"0 auto 0 0"} bg={"transparent"} as={"h5"} fontSize={"18px"}>
                                    {percentageToText((userData?.totalBillableTime/userData?.totalHoursPonto))}
                                </Heading>
                            </Flex>
                            <Flex flexDir={'column'} ml={3} fontSize={"xs"} isTruncated color={"gray.500"} flexBasis={"12%"} p={2} cursor={'pointer'} bg={getBackground('percentualTempoQA')} onClick={() => handleOpenDetails('percentualTempoQA')}>
                                % de QA
                                <Heading m={"0 auto 0 0"} bg={"transparent"} as={"h5"} fontSize={"18px"}>
                                    {percentageToText(userData.totalQaTime / totalTime)}
                                </Heading>
                            </Flex>
                            <Flex flexDir={'column'} ml={3} fontSize={"xs"} isTruncated color={"gray.500"} flexBasis={"12%"} p={2} cursor={'pointer'} bg={getBackground('assertividade')} onClick={() => handleOpenDetails('assertividade')}>
                                % Assert.
                                <Heading m={"0 auto 0 0"} bg={"transparent"} as={"h5"} fontSize={"18px"}>
                                    {percentageToText(userData.totalEstimatedTime / totalTime)}
                                </Heading>
                            </Flex>
                            <Flex flexDir={'column'} ml={3} fontSize={"xs"} isTruncated color={"gray.500"} p={2} flexBasis={"12%"}>
                                % HRs Interno
                                <Heading m={"0 auto 0 0"} bg={"transparent"} as={"h5"} fontSize={"18px"}>
                                    {percentageToText(userData?.internalWorklogs?.totalTime / totalTime)}
                                </Heading>
                            </Flex>
                            <Flex flexDir={'column'} ml={3} fontSize={"xs"} isTruncated color={"gray.500"} p={2} flexBasis={"12%"}>
                                <Tooltip placement={"top"} hasArrow label={"Quantidade de issues trabalhadas"} aria-label={"Quantidade de issues trabalhadas"}>
                                    Qtd de issues
                                </Tooltip>
                                <Heading m={"0 auto 0 0"} bg={"transparent"} as={"h5"} fontSize={"18px"}>
                                    {userData.issueCount}
                                </Heading>
                            </Flex>
                        </> : <Flex alignSelf={"flex-end"} flexDir={'column'} fontSize={"xs"} isTruncated color={"gray.500"} p={2}>
                            <Heading alignSelf={"flex-end"} m={"0"} bg={"transparent"} as={"h5"} fontSize={"18px"}>
                                Usuário sem relacionamento entre Ponto/Jira
                            </Heading>
                        </Flex>}
                    </Flex>
                    {openDetails.type === 'totalHrs' && (
                        <TotalHoursDetails worklogsByDate={userData.worklogsByDate} internalWorklogs={userData.internalWorklogs} />
                    )}
                    {openDetails.type === 'percentualTempoQA' && (
                        <QAPercentageDetails qaIssues={userData.qaIssues} />
                    )}
                    {openDetails.type === 'assertividade' && (
                        <AssertivityDetails issues={userData.issues} />
                    )}
                </Flex>
            }
        </>
    )
}

export default PerformanceRow