
import React from 'react'
import { Row, Col } from 'react-bootstrap'

import PlanningLogo from './PlanningHeader/PlanningLogo';
import GroupingType from './PlanningHeader/GroupingType';
import DateSelector from './PlanningHeader/DateSelector';
import FilterSelector from './PlanningHeader/FilterSelector';
import HideTaskSwitch from './PlanningHeader/HideTaskSwitch';

export default function PlannerFilters({hideFilters}) {

    return (
        <Row className='mt-4'>
            <PlanningLogo/>
            <Col className="px-4 py-2 bg-light rounded gap-10 max-h-14 " >
                <Row>
                    <GroupingType/>
                    <DateSelector/>
                    <FilterSelector {...{hideFilters}}/>
                    <HideTaskSwitch/>
                </Row>
            </Col>
        </Row>
    )
}
