import React from 'react'

const PermissionContext = React.createContext();
const PermissionProvider = (props) => {
    const [permissions,setPermissions] = React.useState()
    return (
        <PermissionContext.Provider value={[permissions,setPermissions]}>
            {props.children}
        </PermissionContext.Provider>
    )
}

export {PermissionContext,PermissionProvider}
