import React from 'react'
import GoogleLogin from 'react-google-login'
import styled from 'styled-components'
import logo from '../img/Frame_3.png'
import authValidator from '../auth/authValidator'

const GoogleLoginContainer = styled.div`
    display:flex;
    margin-top:12%;
`
const GLBox = styled.div`
    width:350px;
    height:380px;
    border-radius:8px;
    margin:0 auto;
    border:1px solid #ddd;
    background-color:white;
    padding:0;
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
`
const GLBtn = styled.div`
    text-align:center;
`
const GLInfo = styled.div`
    height:280px;
    padding-left:35px;
    padding-right:35px;
`
const GLImg = styled.img`
    display:flex;
    margin:0 auto;
`
const GLText = styled.div`
    line-height:20px;
`

const GoogleAuth = ({setAuth,setLoading,setPermissions}) => {
    function successResponse(params) {
        localStorage.setItem('googleAuth',JSON.stringify(params))
        setAuth(params)
        setLoading(false)
        authValidator()
            .then(resp=>setPermissions(resp))
    }
    
    function failureResponse(params) {
        setAuth()
    }

    return (
        <GoogleLoginContainer className={"GoogleLoginContainer"}>
            <GLBox>
                <GLInfo>
                    <GLImg src={logo}>

                    </GLImg>
                    <GLText>
                        Faça login com seu email <b>Maeztra</b> para acessar o aplicativo.
                    </GLText>
                </GLInfo>
                <GLBtn>
                    <GoogleLogin
                    clientId="310049587059-v4o6c2qad7tobuadprf43k8ci9ig70go.apps.googleusercontent.com"
                    buttonText="Entrar"
                    onSuccess={successResponse}
                    onFailure={failureResponse}
                    cookiePolicy={'single_host_origin'}
                    />   
                </GLBtn>
            </GLBox>        
        </GoogleLoginContainer>
    )
}
export default GoogleAuth
