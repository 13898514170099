import { useEffect, useState,useContext } from 'react'
import { useToast, IconButton, Table, Thead, Tbody, Tfoot, Tr, Th, Td, Heading, Spinner, Button, useDisclosure } from "@chakra-ui/react"
import { AuthContext } from '../../context/AuthContext';
import { Flex, Box } from '@chakra-ui/layout'
import PermissionDropdown from './PermissionDropdown';
import api from "../../services/authApi";
import NewUser from './NewUser';
import { UilTrashAlt } from '@iconscout/react-unicons'
import NewPermission from './NewPermission'
import { PermissionContext } from "../../context/PermissionContext";
import { isAllowed } from '../../utils/functions';
import permissionSchema from '../../utils/permissionSchema';

const compareUsers = (a, b) => a.user_name < b.user_name ? -1 : 1

const toastRemoveUser = {
    title: "Usuário removido.",
    status: "success",
    duration: 2000,
    isClosable: true,
    position:"top"
}
const toastRemoveUserError = {
    title: "Ocorreu um erro ao remover o usuário.",
    status: "error",
    duration: 2000,
    isClosable: true,
    position:"top"
}

const PermissionManagement = () => {
    const [users, setUsers] = useState([])
    const [options, setOptions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const { isOpen:isOpenNewUser, onOpen:onOpenNewUser, onClose:onCloseNewUser } = useDisclosure()
    const { isOpen:isOpenNewPermission, onOpen:onOpenNewPermission, onClose:onCloseNewPermission } = useDisclosure()
    const [deleteBtnLoading,setDeleteBtnLoading] = useState(false)
    const toast = useToast()
    const [auth, setAuth] = useContext(AuthContext);
    const [permissions] = useContext(PermissionContext);
    const [notAllowed,setNotAllowed] = useState(true)
    const [allowed] = useState(permissionSchema[window.location.pathname.replace(/\/*$/,'')] || [0])

    useEffect(()=>{
        if(auth){
            api.defaults.headers.common['google-token'] = auth.accessToken
        }
    },[auth])

    //verificando se user tem autorização 
    useEffect(()=>{
        if(permissions){
            let allowedResult = isAllowed(permissions,allowed)
            if(allowedResult){
                setNotAllowed(false)
            }else{
                setNotAllowed(true)
            }
        }
    },[permissions])

    useEffect(() => { 
        if(notAllowed) return
        api.get('/getAllUsersPermissions')
        .then(({ data }) => {
            //não mostrando usuários admin
            let users = data.result.filter(user=>!!!user.permission_ids.find(permission=>permission.id===0))
            setUsers(users.sort(compareUsers))
        })
        .catch(err => {
            if(err?.response?.data && err.response.data.error==='invalid_token'){
                setAuth(null)
            }
        })
    }, [notAllowed])
    
    useEffect(() => {
        if(notAllowed) return
        api.get('getAllPermissions')
        .then(({ data }) => setOptions(data.result))
        .catch(err => {
            if(err?.response?.data && err.response.data.error==='invalid_token'){
                setAuth(null)
            }
        })
        .finally(() => setIsLoading(false))
    }, [notAllowed])

    const deleteUser = (userId)=>{
        setDeleteBtnLoading(true)
        api.delete('/deleteUser',{data:{id:userId}})
        .then((res)=>{
            let newUsers = [...users]
            newUsers = newUsers.filter(user=>!(user.user_id===userId))
            setUsers(newUsers)
            toast(toastRemoveUser)
        })
        .catch((err)=>{
            if(err?.response?.data && err.response.data.error==='invalid_token'){
                setAuth(null)
            }
            toast(toastRemoveUserError)
        }).finally(()=>{
            setDeleteBtnLoading(false)
        })
    }

    return (
        <Flex direction='row' h={'100%'} w={"100%"} fontFamily={'Roboto'}>
            <Box flexBasis='100%' px={'4%'} pt={3} overflow={'auto'}>
                <Box flexBasis='100%'>
                    <Flex>
                        <Heading flexBasis={'50%'} size="lg" as="h3">Gerenciamento de Permissões</Heading>
                        <Flex flexBasis={'50%'} justifyContent={'flex-end'}>
                            <Button onClick={onOpenNewPermission} colorScheme={'yellow'} mr={3}>+ Nova Permissão</Button>
                            <Button onClick={onOpenNewUser} colorScheme={'yellow'}>+ Novo Usuário</Button>
                        </Flex>

                    </Flex>

                    <Box mt={4} background="white" border="1px" borderColor="gray.200">
                        <Heading padding={4} as={"h5"} fontSize={'20px'} pb={3}>Gerenciar</Heading>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Usuário</Th>
                                    <Th>Permissões</Th>
                                    <Th>Email</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {isLoading ? 
                                    <Tr>
                                        <Td colSpan={2} textAlign="center">
                                            <Spinner size="xl" />
                                        </Td> 
                                    </Tr>
                                :
                                    users.map(user => (
                                        <Tr key={user.user_id}>
                                            <Td>{user.user_name}</Td>
                                            <Td py={0}>
                                                <PermissionDropdown 
                                                    options={options}
                                                    permissions={user.permission_ids}
                                                    userId={user.user_id}
                                                />
                                            </Td>
                                            <Td isTruncated maxW={'25%'}>
                                                {user.user_email}
                                            </Td>
                                            <Td>
                                                <IconButton isLoading={deleteBtnLoading} onClick={(e)=>deleteUser(user.user_id)} icon={<UilTrashAlt  size={16} />} />
                                            </Td>
                                        </Tr>
                                    ))
                                }
                            </Tbody>
                            <Tfoot>
                                <Tr>
                                    <Th>Usuário</Th>
                                    <Th>Permissões</Th>
                                    <Th>Email</Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </Box>
                </Box>
            </Box>
            <NewUser {...{users,setUsers,isOpenNewUser,onCloseNewUser}}/>
            <NewPermission {...{options,setOptions,isOpenNewPermission,onCloseNewPermission}}/>
            
        </Flex>
    )
}

export default PermissionManagement