import { useState,useEffect,useContext } from 'react'
import chroma from 'chroma-js'
import Select from 'react-select'
import api from '../../services/authApi'
import { useToast,Spinner } from "@chakra-ui/react"
import * as S from './styles'
import { AuthContext } from '../../context/AuthContext';

const toastSuccess = (text)=>{
    return {
        title: text,
        status: "success",
        duration: 2000,
        isClosable: true,
        position:"top"
    }
}
const toastError = (text) =>{
    return {
        title: text,
        status: "error",
        duration: 2000,
        isClosable: true,
        position:"top"
    }
}

const labelColors = {
    produto: '#9b59b6',
    dev: '#5f89ff',
    coordenador: '#f1c40f',
    gp: '#00ffc2',
    rh: '#2ecc71',
    gerente: '#e67e22',
    externo: '#030517',
    marketing: '#e74c3c',
    design: '#ff0045'
}

const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });


const textToColor = (str)=>{
    try{
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
        }
        return color;
    }catch(e){
        return '#030517'
    }
}

const PermissionDropdown = ({ options, permissions, userId }) => {
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const [auth, setAuth] = useContext(AuthContext);

    useEffect(()=>{
        if(auth){
            api.defaults.headers.common['google-token'] = auth.accessToken
        }
    },[auth])

    const removeAdmPermission = permissions.filter(permission => 
        permission.id !== 0).map(permission => ({
            value: permission.id,
            label: permission.name,
            color: labelColors[permission.name.toLowerCase()] || textToColor(permission.name)
        })
    )

    const removeAdmOption = options.filter(option =>
        option.id !== 0).map(option => ({
            value: option.id,
            label: option.name,
            color: labelColors[option.name.toLowerCase()] || textToColor(option.name)
        })
    )


    const isDisabled = permissions => {
        const isExternal = permissions.filter(permission => permission.id === 1)
        return isExternal.length > 0 && {
            opacity: .6,
            pointerEvents: 'none',
            cursor: 'not-allowed'
        }
    }

    const customStyles = {
        container: (styles) => ({
            ...styles,
            maxWidth: '400px',
            ...isDisabled(permissions)
        }),
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color)
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                    ? data.color
                    : isFocused
                    ? color.alpha(0.1).css()
                    : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                    ? chroma.contrast(color, 'white') > 2
                    ? 'white'
                    : 'black'
                    : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',
            
                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                    !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            }
        },
        multiValue: (styles, { data }) => {
            const color = chroma(data.color)
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
            }
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ...dot(data.color)
        }),
        multiValueRemove: (styles, { data }) => ({
                ...styles,
                color: data.color,
                ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        })
    }

    const handleOnChange = async (_, change) => {
        setLoading(true)
        try {
            if (change.action === 'select-option') {
                api.post('/createUserPermission', {
                    idPermission: change.option.value,
                    idUser: userId
                }).then(()=>{
                    toast(toastSuccess('Permissão adicionada.'))
                }).catch((err)=>{
                    if(err.message.error){
                        setAuth(null)
                    }
                    toast(toastError('Erro ao adicionar permissão.'))
                })
            } else if (change.action === 'remove-value') {
                api.delete('/deleteUserPermission', {
                    data: {
                        idPermission: change.removedValue.value,
                        idUser: userId
                    }
                }).then(()=>{
                    toast(toastSuccess('Permissão removida.'))
                }).catch((err)=>{
                    if(err.message.error){
                        setAuth(null)
                    }
                    toast(toastError('Erro ao remover permissão.'))
                })
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <S.SpinnerWrapper loading={loading?true:false}>
            <Spinner />
            <Select 
                options={removeAdmOption} 
                isMulti
                defaultValue={removeAdmPermission}
                onChange={handleOnChange}
                isClearable={false}
                isOptionDisabled={(option) => option.disabled}
                styles={customStyles}
                />
        </S.SpinnerWrapper>
    )
}

export default PermissionDropdown