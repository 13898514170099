//create context

import React from "react";
import DateObject from "react-date-object";
import { formatDate } from '../utils/formatDate'
import { getIfWeekendOrHoliday } from "../../../utils/functions";

const DateRangeContext = React.createContext();

const DateRangeProvider = ({ children }) => {
    const [dateRange, setDateRange] = React.useState([new DateObject(new Date()), new DateObject(new Date()).add(14, 'day')]);
    const [datesBetween, nOfWeekdays] = getDatesBetween(new Date(dateRange[0]), new Date(dateRange[1]));


    function getDatesBetween(startDate, endDate) { //pega as datas entre a inicial e a final
        const dates = [];
        let nOfWeekdays = 0;
        const currentDate = new Date(startDate);
        // const endDate = new Date(endDate);


        while (currentDate <= endDate) {
            let isWeekDay = false;
            if (getIfWeekendOrHoliday(currentDate)) {
                isWeekDay = true
                nOfWeekdays++
            }
            const formattedDate = {
                date: new Date(currentDate).setHours(0, 0, 0, 0),
                ...formatDate(currentDate),
                isWeekDay
            };
            dates.push(formattedDate);
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return [dates, nOfWeekdays];
    }



    return (
        <DateRangeContext.Provider value={[dateRange, setDateRange, datesBetween, nOfWeekdays]}>
            {children}
        </DateRangeContext.Provider>
    );
}

export { DateRangeContext, DateRangeProvider };