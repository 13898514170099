import React from 'react'
import { Col } from 'react-bootstrap'

const PlanningLogo = () => {
  return (
    <Col sm={2} className='logoPlanning'>
        <svg xmlns="http://www.w3.org/2000/svg" width="145" height="24" viewBox="0 0 145 24" fill="none">
          <path d="M63 4C63 1.79086 64.7909 0 67 0H141C143.209 0 145 1.79086 145 4V20C145 22.2091 143.209 24 141 24H67C64.7909 24 63 22.2091 63 20V4Z" fill="#1E1E1E"/>
          <path d="M21.3547 0.843C21.604 0.571001 21.8988 0.383999 22.239 0.282C22.579 0.18 22.919 0.185749 23.259 0.299C23.599 0.41275 23.8762 0.616751 24.0922 0.911001C24.3075 1.206 24.4152 1.535 24.4152 1.89725V22.3C24.4152 22.776 24.2453 23.1785 23.9053 23.507C23.565 23.8357 23.1683 24 22.715 24C22.239 24 21.836 23.8357 21.5077 23.507C21.1787 23.1785 21.0147 22.776 21.0147 22.3V6.79375C20.221 7.76875 19.3937 8.80025 18.5322 9.88825C17.6705 10.9765 16.8662 11.9965 16.118 12.9485C15.234 14.0368 14.3722 15.1248 13.5337 16.213C13.216 16.644 12.774 16.859 12.2075 16.859C11.6407 16.859 11.1985 16.644 10.8812 16.213C10.0422 15.1248 9.18125 14.0368 8.297 12.9485C7.549 11.9965 6.73825 10.9765 5.86575 9.88825C4.99275 8.80025 4.17075 7.76875 3.4005 6.79375V22.3C3.4005 22.776 3.23575 23.1785 2.90725 23.507C2.5785 23.8357 2.17625 24 1.70025 24C1.24625 24 0.85 23.8357 0.51 23.507C0.17 23.1785 0 22.776 0 22.3V1.89725C0 1.535 0.10725 1.206 0.323 0.911001C0.53825 0.616751 0.816 0.41275 1.15625 0.299C1.496 0.185749 1.83625 0.18 2.17625 0.282C2.51625 0.383999 2.7995 0.571001 3.0265 0.843C4.36375 2.54325 5.633 4.153 6.83475 5.67175C7.33325 6.32925 7.84925 6.98675 8.382 7.644C8.9145 8.30175 9.41925 8.931 9.89525 9.53125C10.3712 10.1322 10.8132 10.682 11.2215 11.1805C11.6295 11.6795 11.958 12.0872 12.2075 12.4045C12.4567 12.0872 12.7797 11.6795 13.1767 11.1805C13.573 10.682 14.0098 10.1322 14.4858 9.53125C14.962 8.931 15.4603 8.30175 15.982 7.644C16.5033 6.98675 17.0245 6.32925 17.5462 5.67175C18.7475 4.153 20.0168 2.54325 21.3547 0.843Z" fill="#1E1E1E"/>
          <path d="M35.903 18.6442C36.5713 17.8622 37.2913 17.012 38.0623 16.094C38.8328 15.1757 39.6433 14.2183 40.4935 13.2207C41.3438 12.223 42.1768 11.2373 42.9928 10.2622C44.752 8.1685 46.6323 5.943 48.6233 3.59725H52.9498C52.496 4.119 51.9405 4.77025 51.2835 5.5525C50.6258 6.33475 49.9115 7.1905 49.141 8.11975C48.3703 9.04975 47.56 10.013 46.71 11.0103C45.8598 12.008 45.0268 12.9943 44.2105 13.9685C42.4603 16.0525 40.5993 18.2667 38.6383 20.5995H34.2538C34.684 20.0782 35.234 19.4262 35.903 18.6442ZM56.6223 20.5995H47.8215H43.0543C43.4848 20.0782 44.0345 19.4262 44.7035 18.6442C45.372 17.8622 46.092 17.012 46.863 16.094C47.6333 15.1757 48.444 14.2183 49.294 13.2207C50.1445 12.223 50.9775 11.2373 51.7938 10.2622C53.6978 7.9955 55.7383 5.58125 57.9145 3.01925C58.1865 2.679 58.3225 2.305 58.3225 1.897C58.3225 1.625 58.2655 1.387 58.1523 1.18275C58.0165 0.88875 57.8125 0.650499 57.5403 0.46875C57.2683 0.28775 56.9623 0.196751 56.6223 0.196751H47.8215H39.416H30.6153C30.1393 0.196751 29.7365 0.366749 29.408 0.706749C29.0793 1.04675 28.915 1.44375 28.915 1.897C28.915 2.37325 29.0793 2.77575 29.408 3.10425C29.7365 3.433 30.1393 3.59725 30.6153 3.59725H39.416H44.149C43.6953 4.119 43.14 4.77025 42.4828 5.5525C41.825 6.33475 41.111 7.1905 40.3405 8.11975C39.5698 9.04975 38.7593 10.013 37.9093 11.0103C37.059 12.008 36.226 12.9943 35.4098 13.9685C33.5058 16.2355 31.4765 18.65 29.323 21.2115C29.051 21.5292 28.915 21.8915 28.915 22.2997C28.915 22.5717 28.9603 22.8097 29.051 23.014C29.3683 23.6715 29.8895 24 30.6153 24H39.416H47.8215H56.6223C57.0983 24 57.5005 23.8358 57.8293 23.5068C58.1578 23.1785 58.3225 22.7757 58.3225 22.2997C58.3225 21.8237 58.1578 21.4212 57.8293 21.0925C57.5005 20.764 57.0983 20.5995 56.6223 20.5995Z" fill="#1E1E1E"/>
          <path d="M69.574 20.384C69.214 20.384 68.938 20.288 68.746 20.096C68.554 19.904 68.458 19.622 68.458 19.25V9.188C68.458 8.816 68.554 8.534 68.746 8.342C68.938 8.15 69.208 8.054 69.556 8.054C69.916 8.054 70.192 8.15 70.384 8.342C70.576 8.534 70.672 8.816 70.672 9.188V10.592L70.474 10.052C70.654 9.44 71.02 8.948 71.572 8.576C72.136 8.204 72.79 8.018 73.534 8.018C74.302 8.018 74.974 8.204 75.55 8.576C76.138 8.948 76.594 9.476 76.918 10.16C77.242 10.832 77.404 11.642 77.404 12.59C77.404 13.526 77.242 14.342 76.918 15.038C76.594 15.722 76.144 16.25 75.568 16.622C74.992 16.994 74.314 17.18 73.534 17.18C72.802 17.18 72.16 17 71.608 16.64C71.056 16.268 70.684 15.788 70.492 15.2H70.708V19.25C70.708 19.622 70.606 19.904 70.402 20.096C70.21 20.288 69.934 20.384 69.574 20.384ZM72.904 15.47C73.348 15.47 73.738 15.362 74.074 15.146C74.41 14.93 74.668 14.612 74.848 14.192C75.04 13.76 75.136 13.226 75.136 12.59C75.136 11.63 74.932 10.916 74.524 10.448C74.116 9.968 73.576 9.728 72.904 9.728C72.46 9.728 72.07 9.836 71.734 10.052C71.398 10.256 71.134 10.574 70.942 11.006C70.762 11.426 70.672 11.954 70.672 12.59C70.672 13.538 70.876 14.258 71.284 14.75C71.692 15.23 72.232 15.47 72.904 15.47Z" fill="#FFFF00"/>
          <path d="M82.257 17.18C81.261 17.18 80.511 16.898 80.007 16.334C79.503 15.758 79.251 14.918 79.251 13.814V5.3C79.251 4.928 79.347 4.646 79.539 4.454C79.731 4.262 80.007 4.166 80.367 4.166C80.727 4.166 81.003 4.262 81.195 4.454C81.399 4.646 81.501 4.928 81.501 5.3V13.706C81.501 14.258 81.615 14.666 81.843 14.93C82.083 15.194 82.419 15.326 82.851 15.326C82.947 15.326 83.037 15.326 83.121 15.326C83.205 15.314 83.289 15.302 83.373 15.29C83.541 15.266 83.655 15.314 83.715 15.434C83.775 15.542 83.805 15.77 83.805 16.118C83.805 16.418 83.745 16.652 83.625 16.82C83.505 16.988 83.307 17.09 83.031 17.126C82.911 17.138 82.785 17.15 82.653 17.162C82.521 17.174 82.389 17.18 82.257 17.18Z" fill="#FFFF00"/>
          <path d="M87.897 17.18C87.261 17.18 86.691 17.06 86.187 16.82C85.695 16.568 85.305 16.232 85.017 15.812C84.741 15.392 84.603 14.918 84.603 14.39C84.603 13.742 84.771 13.232 85.107 12.86C85.443 12.476 85.989 12.2 86.745 12.032C87.501 11.864 88.515 11.78 89.787 11.78H90.687V13.076H89.805C89.061 13.076 88.467 13.112 88.023 13.184C87.579 13.256 87.261 13.382 87.069 13.562C86.889 13.73 86.799 13.97 86.799 14.282C86.799 14.678 86.937 15.002 87.213 15.254C87.489 15.506 87.873 15.632 88.365 15.632C88.761 15.632 89.109 15.542 89.409 15.362C89.721 15.17 89.967 14.912 90.147 14.588C90.327 14.264 90.417 13.892 90.417 13.472V11.402C90.417 10.802 90.285 10.37 90.021 10.106C89.757 9.842 89.313 9.71 88.689 9.71C88.341 9.71 87.963 9.752 87.555 9.836C87.159 9.92 86.739 10.064 86.295 10.268C86.067 10.376 85.863 10.406 85.683 10.358C85.515 10.31 85.383 10.214 85.287 10.07C85.191 9.914 85.143 9.746 85.143 9.566C85.143 9.386 85.191 9.212 85.287 9.044C85.383 8.864 85.545 8.732 85.773 8.648C86.325 8.42 86.853 8.258 87.357 8.162C87.873 8.066 88.341 8.018 88.761 8.018C89.625 8.018 90.333 8.15 90.885 8.414C91.449 8.678 91.869 9.08 92.145 9.62C92.421 10.148 92.559 10.832 92.559 11.672V15.992C92.559 16.364 92.469 16.652 92.289 16.856C92.109 17.048 91.851 17.144 91.515 17.144C91.179 17.144 90.915 17.048 90.723 16.856C90.543 16.652 90.453 16.364 90.453 15.992V15.128H90.597C90.513 15.548 90.345 15.914 90.093 16.226C89.853 16.526 89.547 16.76 89.175 16.928C88.803 17.096 88.377 17.18 87.897 17.18Z" fill="#FFFF00"/>
          <path d="M95.9588 17.144C95.5988 17.144 95.3228 17.048 95.1308 16.856C94.9388 16.652 94.8428 16.364 94.8428 15.992V9.188C94.8428 8.816 94.9388 8.534 95.1308 8.342C95.3228 8.15 95.5928 8.054 95.9408 8.054C96.2888 8.054 96.5588 8.15 96.7508 8.342C96.9428 8.534 97.0388 8.816 97.0388 9.188V10.412L96.8408 9.962C97.1048 9.326 97.5128 8.846 98.0648 8.522C98.6288 8.186 99.2648 8.018 99.9728 8.018C100.681 8.018 101.263 8.15 101.719 8.414C102.175 8.678 102.517 9.08 102.745 9.62C102.973 10.148 103.087 10.82 103.087 11.636V15.992C103.087 16.364 102.991 16.652 102.799 16.856C102.607 17.048 102.331 17.144 101.971 17.144C101.611 17.144 101.329 17.048 101.125 16.856C100.933 16.652 100.837 16.364 100.837 15.992V11.744C100.837 11.06 100.705 10.562 100.441 10.25C100.189 9.938 99.7928 9.782 99.2528 9.782C98.5928 9.782 98.0648 9.992 97.6688 10.412C97.2848 10.82 97.0928 11.366 97.0928 12.05V15.992C97.0928 16.76 96.7148 17.144 95.9588 17.144Z" fill="#FFFF00"/>
          <path d="M106.488 17.144C106.128 17.144 105.852 17.048 105.66 16.856C105.468 16.652 105.372 16.364 105.372 15.992V9.188C105.372 8.816 105.468 8.534 105.66 8.342C105.852 8.15 106.122 8.054 106.47 8.054C106.818 8.054 107.088 8.15 107.28 8.342C107.472 8.534 107.568 8.816 107.568 9.188V10.412L107.37 9.962C107.634 9.326 108.042 8.846 108.594 8.522C109.158 8.186 109.794 8.018 110.502 8.018C111.21 8.018 111.792 8.15 112.248 8.414C112.704 8.678 113.046 9.08 113.274 9.62C113.502 10.148 113.616 10.82 113.616 11.636V15.992C113.616 16.364 113.52 16.652 113.328 16.856C113.136 17.048 112.86 17.144 112.5 17.144C112.14 17.144 111.858 17.048 111.654 16.856C111.462 16.652 111.366 16.364 111.366 15.992V11.744C111.366 11.06 111.234 10.562 110.97 10.25C110.718 9.938 110.322 9.782 109.782 9.782C109.122 9.782 108.594 9.992 108.198 10.412C107.814 10.82 107.622 11.366 107.622 12.05V15.992C107.622 16.76 107.244 17.144 106.488 17.144Z" fill="#FFFF00"/>
          <path d="M117.017 17.126C116.657 17.126 116.381 17.018 116.189 16.802C115.997 16.586 115.901 16.286 115.901 15.902V9.296C115.901 8.9 115.997 8.6 116.189 8.396C116.381 8.18 116.657 8.072 117.017 8.072C117.377 8.072 117.653 8.18 117.845 8.396C118.049 8.6 118.151 8.9 118.151 9.296V15.902C118.151 16.286 118.055 16.586 117.863 16.802C117.671 17.018 117.389 17.126 117.017 17.126ZM117.017 6.398C116.597 6.398 116.267 6.296 116.027 6.092C115.799 5.876 115.685 5.582 115.685 5.21C115.685 4.826 115.799 4.532 116.027 4.328C116.267 4.124 116.597 4.022 117.017 4.022C117.449 4.022 117.779 4.124 118.007 4.328C118.235 4.532 118.349 4.826 118.349 5.21C118.349 5.582 118.235 5.876 118.007 6.092C117.779 6.296 117.449 6.398 117.017 6.398Z" fill="#FFFF00"/>
          <path d="M121.605 17.144C121.245 17.144 120.969 17.048 120.777 16.856C120.585 16.652 120.489 16.364 120.489 15.992V9.188C120.489 8.816 120.585 8.534 120.777 8.342C120.969 8.15 121.239 8.054 121.587 8.054C121.935 8.054 122.205 8.15 122.397 8.342C122.589 8.534 122.685 8.816 122.685 9.188V10.412L122.487 9.962C122.751 9.326 123.159 8.846 123.711 8.522C124.275 8.186 124.911 8.018 125.619 8.018C126.327 8.018 126.909 8.15 127.365 8.414C127.821 8.678 128.163 9.08 128.391 9.62C128.619 10.148 128.733 10.82 128.733 11.636V15.992C128.733 16.364 128.637 16.652 128.445 16.856C128.253 17.048 127.977 17.144 127.617 17.144C127.257 17.144 126.975 17.048 126.771 16.856C126.579 16.652 126.483 16.364 126.483 15.992V11.744C126.483 11.06 126.351 10.562 126.087 10.25C125.835 9.938 125.439 9.782 124.899 9.782C124.239 9.782 123.711 9.992 123.315 10.412C122.931 10.82 122.739 11.366 122.739 12.05V15.992C122.739 16.76 122.361 17.144 121.605 17.144Z" fill="#FFFF00"/>
          <path d="M135.123 20.42C134.475 20.42 133.845 20.36 133.233 20.24C132.633 20.12 132.087 19.934 131.595 19.682C131.367 19.562 131.211 19.418 131.127 19.25C131.055 19.082 131.031 18.908 131.055 18.728C131.091 18.56 131.163 18.41 131.271 18.278C131.391 18.146 131.535 18.056 131.703 18.008C131.871 17.96 132.045 17.984 132.225 18.08C132.753 18.344 133.251 18.512 133.719 18.584C134.199 18.668 134.607 18.71 134.943 18.71C135.747 18.71 136.347 18.512 136.743 18.116C137.151 17.732 137.355 17.156 137.355 16.388V14.768H137.517C137.337 15.38 136.959 15.872 136.383 16.244C135.819 16.604 135.177 16.784 134.457 16.784C133.665 16.784 132.975 16.604 132.387 16.244C131.799 15.872 131.343 15.356 131.019 14.696C130.695 14.036 130.533 13.268 130.533 12.392C130.533 11.732 130.623 11.138 130.803 10.61C130.995 10.07 131.259 9.608 131.595 9.224C131.943 8.84 132.357 8.546 132.837 8.342C133.329 8.126 133.869 8.018 134.457 8.018C135.201 8.018 135.849 8.204 136.401 8.576C136.965 8.936 137.331 9.416 137.499 10.016L137.319 10.466V9.188C137.319 8.816 137.415 8.534 137.607 8.342C137.811 8.15 138.087 8.054 138.435 8.054C138.795 8.054 139.071 8.15 139.263 8.342C139.455 8.534 139.551 8.816 139.551 9.188V16.118C139.551 17.546 139.173 18.62 138.417 19.34C137.661 20.06 136.563 20.42 135.123 20.42ZM135.069 15.074C135.537 15.074 135.939 14.966 136.275 14.75C136.611 14.534 136.869 14.228 137.049 13.832C137.241 13.424 137.337 12.944 137.337 12.392C137.337 11.564 137.133 10.916 136.725 10.448C136.317 9.968 135.765 9.728 135.069 9.728C134.601 9.728 134.199 9.836 133.863 10.052C133.527 10.268 133.263 10.574 133.071 10.97C132.891 11.366 132.801 11.84 132.801 12.392C132.801 13.22 133.005 13.874 133.413 14.354C133.821 14.834 134.373 15.074 135.069 15.074Z" fill="#FFFF00"/>
        </svg>
    </Col>
  )
}

export default PlanningLogo