//0 Adm
//1 Externo
//3 Dev
//4 Produto
//5 Gerente
//6 Marketing
//7 Design
//8 RH
//9 GP
//10 Coordenador
//14 Coordenador +

export default {
    '/planning':[0,17],
    '/kpi':[10,0,14],
    '/squad-management':[0,10,14],
    '/position-management':[0,10,14],
    '/jira-accounts':[0,15],
    '/permission-management':[0,18],
    '/permission-management/management':[0,18],
    '/kpi/home':[10,0,14],
    '/kpi/home/details':[10,0,14],
    '/kpi/performance-ip-ev':[10,0,14],
    '/kpi/performance-ip-ev/details':[10,0,14],
    '/kpi/performance-general':[10,0,14],
    '/kpi/performance-general/details':[10,0,14],
    '/kpi/projects':[10,0,14],
    '/kpi/projects/details':[10,0,14],
    '/kpi/projects-restrict':[14,0],
    '/kpi/projects-restrict/details':[14,0],
    '/kpi/reports':[14,0],
    '/kpi/reports/details':[14,0],
    '/kpi/profitability-project':[14,0],
    '/kpi/profitability-user':[14,0],
    '/performance':[0,19],
    '/userRelations':[0,21],
}