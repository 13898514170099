const authUrl = process.env.NODE_ENV === "development" ? '//localhost:2999' : 'https://pass.maeztra.com/'

module.exports = ()=>{    
    return new Promise((resolve,reject)=>{
        try{
            let googleAuth = localStorage.getItem('googleAuth')?JSON.parse(localStorage.getItem('googleAuth')):''
            if(!googleAuth){
                console.warn('No googleAuth')
                return reject('No googleAuth');                
            }

            fetch(`${authUrl}/api/getUserPermissions`,{headers:{'google-token':googleAuth.accessToken}})
            .then((resp)=>resp.json())
            .then((resp)=>{
                if(!resp || !resp.email || !resp.email.includes('@maeztra.')){
                    console.warn('Not a maeztra email')
                    reject('Not a maeztra email')
                }else{
                    if(resp.result && resp.result[0] && resp.result[0].permission_ids){
                        resolve(resp.result[0].permission_ids)
                    }else{
                        resolve([])
                    }
                }
            }).catch((err)=>{
                reject(err)
            })
        }catch(e){
            console.warn(e.message)
            reject(e)
        }
    })
}