import React from 'react';
import ReactDOM from 'react-dom';
import './style/App.scss';
import App from './App';
import {AuthProvider} from "./context/AuthContext"
import {PermissionProvider} from "./context/PermissionContext"
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
} from '@apollo/client';

//alterar no futuro para trabalhar com cache
const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'cache-first',
    },
    query: {
        fetchPolicy: 'cache-first',
    },
}

const client = new ApolloClient({
    uri:'//localhost:4009',
    cache: new InMemoryCache(),
    defaultOptions
});


ReactDOM.render(
  <React.StrictMode>
      <AuthProvider>       
        <PermissionProvider>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </PermissionProvider>
      </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);