import { Container } from "react-bootstrap";
import { DateRangeProvider } from "./context/DateRangeContext";
import PerformanceContent from "./PerformanceContent";
import PerformanceFilters from "./PerformanceFilters";
import { Flex } from "@chakra-ui/react";
import { UserFilterProvider } from "../Planning/context/UserFilterContext";

export default function PerformanceDashboard() {
    //default dates to be now and now-15 days
    const startDate = new Date()
    const endDate = new Date()
    startDate.setDate(startDate.getDate() - 15)
    return (
        <UserFilterProvider>
            <DateRangeProvider startDate={startDate} endDate={endDate}>
                <Flex flexDir={"row"} width={'100%'} h={'100%'}fontFamily={'Roboto'}>
                    <Flex pb={4} sx={{gap:'24px'}}  flexDir={"column"} width={'100%'} flexBasis='100%' mr={0} ml={'auto'} px={'4%'} pt={3} overflow={'auto'}>
                        <PerformanceFilters />
                        <PerformanceContent />
                    </Flex>
                </Flex>
            </DateRangeProvider>
        </UserFilterProvider>
    )
}