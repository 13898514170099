import React from 'react'
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
} from '@chakra-ui/react'
import { secondsToHours } from '../PerformanceContent'


export default function TotalHoursDetails({qaIssues}) {

    return    <Box maxH={'350px'} overflow={'auto'}>
        <TableContainer>
        <Table variant='striped' >
            <Thead>
                <Tr>
                    <Th>Key</Th>
                    <Th>Link da Tarefa</Th>
                    <Th>Horas Consumidas</Th>
                </Tr>
            </Thead>
            <Tbody>
                {Object.keys(qaIssues).map((key, index) => (
                    <Tr key={`qahours-${key}-${index}`}>
                        <Td py={2}>{key}</Td>
                        <Td _hover={{"textDecor":"underline"}} py={2}><a href={`https://maeztra.atlassian.net/browse/${key}`} target='blank'>https://maeztra.atlassian.net/browse/{key}</a></Td>
                        <Td py={2}>{secondsToHours(qaIssues[key].timeUsed)}</Td>
                    </Tr>
                ))}
            </Tbody>

        </Table>
    </TableContainer>
    </Box>

}