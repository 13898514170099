import React from "react";
import { useToast, Flex, Box, Text, Button, Spinner, AccordionItem, Badge, AccordionButton, AccordionPanel, AccordionIcon, Heading, Input, InputRightAddon, InputGroup, Switch, Tooltip } from "@chakra-ui/react";
import { getJiraProject, patchJiraProject, sendWebhook } from "../../services/jira";
import ptBR from "date-fns/locale/pt-BR";
import { AuthContext } from "../../context/AuthContext";
import DatePicker, { registerLocale } from "react-datepicker";
import JiraAccountCycle from "./JiraAccountCycle";
import JiraAccountExtraTime from "./JiraAccountExtraTime";
import AccountReportButton from "./AccountReportButton";
import AccountCloseProject from "./AccountCloseProject";
import { accountSavedFailure, accountSavedSuccess, hoursNotFilled, invalidTokenToast } from "../../utils/toasts";
import { UilQuestionCircle } from "@iconscout/react-unicons";
import JiraSquadSelect from "./JiraSquadSelect";

registerLocale("br", ptBR);

const defaultCycle = [];

const JiraAccountAccordionItem = ({ name, accountKey, id, status, isOpen, onOpen, onClose, setModalProps }) => {
    const [auth] = React.useContext(AuthContext);
    const [accountDetails, setAccountDetails] = React.useState(null);
    const [accountCycles, setAccountCycles] = React.useState(null);
    const [itemIsOpen, setItemIsOpen] = React.useState(isOpen);
    const [loading, setLoading] = React.useState(true);
    //usar onblur
    const [rriId, setRriId] = React.useState("");
    const [startCycle, setStartCycle] = React.useState("");
    const [squadKey, setSquadKey] = React.useState(""); 
    const [squadName,setSquadName] = React.useState("")

    const [endCycle, setEndCycle] = React.useState("");
    const [offsetTime, setOffsetTime] = React.useState("");
    const [accountManager, setAccountManager] = React.useState("");
    const toast = useToast();
    const formatter = new Intl.NumberFormat("en-US", {
        useGrouping: false,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    //closed account
    const [isAccountClosed, setIsAccountClosed] = React.useState("");

    React.useEffect(() => {
        setItemIsOpen(isOpen);
    }, [isOpen]);

    React.useEffect(() => {
        if (itemIsOpen && !accountDetails) {
            getAccountDetails();
        }
    }, [itemIsOpen]);

    React.useEffect(() => {
        if (accountCycles && accountCycles.length) {
            let newAccountDetails = { ...accountDetails };
            const { totalHoursAvailable, remainingHours } = getTotalAndRemainingHours();
            newAccountDetails.totalHoursAvailable = totalHoursAvailable;
            newAccountDetails.remainingHours = remainingHours;
            setAccountDetails(newAccountDetails);
        }
    }, [accountCycles]);
    

    function getAccountDetails() {
        getJiraProject(auth.accessToken, id)
            .then((response) => {
                if (response.data) {
                    const { runrunitId, startCycle, endCycle, offsetTime, accountManager, cycles, closedDate,jiraSquad,jiraSquadKey} = response.data;
                    setAccountDetails(response.data);
                    setRriId(runrunitId);
                    setStartCycle(startCycle);
                    setEndCycle(endCycle);
                    setOffsetTime(offsetTime);
                    setAccountManager(accountManager);
                    setAccountCycles(JSON.parse(cycles || "[]"));
                    setIsAccountClosed(closedDate ? new Date(closedDate) : "");
                    setSquadName(jiraSquad)
                    setSquadKey(jiraSquadKey)
                } else {
                    const defaultAccountDetails = {
                        startCycle: 1,
                        endCycle: 1,
                        atCycle: 1,
                        startDate: new Date().toISOString(),
                        cycles: "[]",
                        accountName: name,
                        accountKey: accountKey,
                        accountId: id,
                        status: status,
                        purgeAccumulatedHours: false,
                        jiraSquad:"",
                        jiraSquadKey:""
                    };

                    setAccountDetails(defaultAccountDetails);
                    setAccountCycles(defaultCycle);
                }
                setLoading(false);
            })
            .catch((e) => {
                toast(invalidTokenToast);
            });
    }

    function changeDate(e) {
        let newAccountDetails = { ...accountDetails };
        newAccountDetails.startDate = e.toISOString();
        let newAccountCycles = updateCycleDates({ accountDetails: newAccountDetails, accountCycles });
        setAccountCycles(newAccountCycles);
        newAccountDetails.atCycle = getCurrentCycleNumber(newAccountCycles);
        setAccountDetails(newAccountDetails);
    }

    function updateCycleDates({ accountCycles, accountDetails }) {
        let newAccountCycles = JSON.parse(JSON.stringify(accountCycles));
        newAccountCycles = newAccountCycles.map((cycle) => {
            cycle.cycleStartsAt = getCycleStartDate(cycle.cycleNumber, accountDetails.startDate, accountDetails.startCycle);
            return cycle;
        });
        return newAccountCycles;
    }

    function getCurrentCycleNumber(accountCycles) {
        let date = new Date();
        if (!accountCycles.length || new Date(accountCycles[0].cycleStartsAt) > date) return 1;
        if (date > new Date(accountCycles[accountCycles.length - 1].cycleStartsAt)) return accountCycles[accountCycles.length - 1].cycleNumber;
        let curr;

        for (var i = 0; i < accountCycles.length; i++) {
            if (new Date(accountCycles[i]?.cycleStartsAt) && date > new Date(accountCycles[i].cycleStartsAt) && date < new Date(accountCycles[i + 1].cycleStartsAt)) {
                curr = accountCycles[i].cycleNumber;
            }
        }
        return curr;
    }

    function getCycleStartDate(cycleNumber, newDate) {
        let cycleDiff = cycleNumber - startCycle;
        let date = new Date(newDate ?? accountDetails.startDate);
        if (cycleDiff == 0) return date;
        date.setMonth(date.getMonth() + cycleDiff);
        return date.toISOString();
    }

    function changeOffsetTime(e) {
        let value = e.target.value;
        value = Number(value);
        if (!e.target.validity.valid || isNaN(value)) return;
        setOffsetTime(value * 60);
    }

    function changeStartCycle(e) {
        let value = e.target.value;

        if (!e.target.validity.valid || isNaN(value)) return;

        setStartCycle(value == 0 ? value : Number(value));
    }

    function changeEndCycle(e) {
        let value = e.target.value;
        if (!e.target.validity.valid || isNaN(value)) return;
        setEndCycle(value == 0 ? value : Number(value));
    }

    function saveAccountData(e) {
        let data = JSON.parse(JSON.stringify(accountDetails));
        //adicionando squad aqui para evitar modificar o objeto accountDetails toda vez q eu mudar o squad
        data.jiraSquad = squadName
        data.jiraSquadKey = squadKey
        let error = false;
        accountCycles.forEach((cycle) => {
            if (!cycle.cycleProjectedTime) {
                error = true;
            }
        });

        if (error) {
            toast(hoursNotFilled);
            return;
        }
        data.cycles = JSON.stringify(accountCycles);
        delete data.createdAt;
        patchJiraProject(auth.accessToken, data)
            .then((response) => {
                toast(accountSavedSuccess);
                //Updating Robocop
                sendWebhook(auth.accessToken,{ accountKey, accountId: id });
            })
            .catch((response) => {
                toast(accountSavedFailure);
            });
    }

    function changeRunRunItId(e) {
        let value = e.target.value;
        if (!e.target.validity.valid || !value) {
            value = "";
        }
        setRriId(value ? Number(value) : "");
    }

    function changeAccountManager(e) {
        let value = e.target.value;
        setAccountManager(value);
    }

    function changeAccountRunRunItId(e) {
        let newAccountDetails = { ...accountDetails };
        newAccountDetails.runrunitId = rriId;
        setAccountDetails(newAccountDetails);
    }

    function changeAccountAccountManager(e) {
        let newAccountDetails = { ...accountDetails };
        newAccountDetails.accountManager = accountManager;
        setAccountDetails(newAccountDetails);
    }

    function changePurgeAccumulatedHours(e) {
        const isChecked = e.target.checked;
        let newAccountDetails = { ...accountDetails };
        newAccountDetails.purgeAccumulatedHours = isChecked;
        setAccountDetails(newAccountDetails);
    }

    function changeAccountOffsetTime(e) {
        let value = e.target.value;
        value = Number(value * 60);
        let newAccountDetails = { ...accountDetails };
        newAccountDetails.offsetTime = value;
        const { totalHoursAvailable, remainingHours } = getTotalAndRemainingHours();
        newAccountDetails.totalHoursAvailable = totalHoursAvailable;
        newAccountDetails.remainingHours = remainingHours;
        setAccountDetails(newAccountDetails);
    }
    function changeAccountStartCycle(e) {
        let value = e.target.value;
        value = Number(value);
        if (accountDetails.endCycle && value > accountDetails.endCycle) {
            value = accountDetails.endCycle;
        }
        if (!value) value = null;

        let newAccountDetails = { ...accountDetails };
        newAccountDetails.startCycle = value;
        setStartCycle(value);
        generateCycles(newAccountDetails);
    }
    function changeAccountEndCycle(e) {
        let value = e.target.value;
        value = Number(value);
        if (accountDetails.startCycle && value < accountDetails.startCycle) {
            value = accountDetails.startCycle;
        }
        if (!value) value = null;
        let newAccountDetails = { ...accountDetails };
        newAccountDetails.endCycle = value;
        setEndCycle(value);
        generateCycles(newAccountDetails);
    }

    function generateCycles(newAccountDetails) {
        let newAccountCycles = JSON.parse(JSON.stringify(accountCycles));
        let { startCycle, endCycle, startDate } = newAccountDetails;
        let newCycles = [];
        if(startCycle && endCycle){
            for (let i = startCycle; i <= endCycle; i++) {
                let cycle = newAccountCycles.find((cycle) => cycle.cycleNumber == i);
                if (cycle) {
                    newCycles.push(cycle);
                } else {
                    newCycles.push({
                        cycleNumber: i,
                        cycleProjectedTime: 0,
                        advanceTime: 0,
                        extraTime: [],
                        cycleStartsAt: getCycleStartDate(i, startDate, startCycle),
                    });
                }
            }
        }else{
            newCycles = []
        }
        newAccountCycles = updateCycleDates({ accountCycles: newCycles, accountDetails: newAccountDetails });
        newAccountDetails.atCycle = getCurrentCycleNumber(newAccountCycles);
        setAccountCycles(newAccountCycles);

        setAccountDetails(newAccountDetails);
    }

    function getTotalAndRemainingHours() {
        let projectedTimeHr = 0;
        let extraTimeHr = 0;
        accountCycles.forEach((cycle) => {
            projectedTimeHr += cycle.cycleProjectedTime;
            cycle.extraTime.forEach((extraTime) => {
                extraTimeHr += extraTime.time;
            });
        });
        projectedTimeHr = projectedTimeHr;
        extraTimeHr = extraTimeHr;
        let totalHoursAvailable = Number(extraTimeHr + projectedTimeHr + (offsetTime ?? 0));
        let remainingHours = totalHoursAvailable - (accountDetails.totalHoursUsed ?? 0);
        return { totalHoursAvailable, remainingHours };
    }

    function calculateTime(cycles) {
        let totalTime;
        let offsetTimeHr = formatter.format(offsetTime / 3600);
        let projectedTimeHr = 0;
        let extraTimeHr = 0;
        cycles.forEach((cycle) => {
            projectedTimeHr += cycle.cycleProjectedTime;
            cycle.extraTime.forEach((extraTime) => {
                extraTimeHr += extraTime.time;
            });
        });
        projectedTimeHr = formatter.format(projectedTimeHr / 3600);
        extraTimeHr = formatter.format(extraTimeHr / 3600);
        totalTime = formatter.format(Number(extraTimeHr) + Number(projectedTimeHr) + Number(offsetTimeHr));
        let res = [];
        if (offsetTimeHr) {
            res.push(`${offsetTimeHr} hrs`);
        }
        if (projectedTimeHr) {
            res.push(`${projectedTimeHr} hrs`);
        }
        if (extraTimeHr) {
            res.push(`${extraTimeHr} hrs`);
        }
        return `${totalTime} hrs (${res.join(" + ")})`;
    }

    function getSelectedDate() {
        let d = new Date(accountDetails.startDate);
        return d;
    }

    function getBadge() {
        if (status) {
            if (status == "OPEN") return <Badge colorScheme="green">Aberto</Badge>;
            if (status == "ARCHIVED") return <Badge colorScheme="red">Arquivado</Badge>;
            if (status == "CLOSED") return <Badge colorScheme="gray">Fechado</Badge>;
        }
        return <></>;
    }

    return (
        <AccordionItem minH={"53px"} borderRadius={"12px"} w={"100%"} mb={3} bg={"white"} sx={{ overflowAnchor: "auto;" }}>
            <Flex justifyContent={"space-between"} alignItems={"center"}>
                <AccordionButton>
                    <Box w={"100%"} p={2} bg={"transparent"} d={"flex"} textAlign={"left"}>
                        <Flex flexBasis={"100%"} justifyContent={"flex-end"}>
                            <Heading m={"0 auto 0 0"} bg={"transparent"} as={"h5"} fontSize={"16px"}>
                                {name}
                            </Heading>
                            <AccordionIcon />
                            <Text ml={3} fontSize={"xs"} isTruncated color={"gray.500"} flexBasis={"25%"}>
                                Key da account: <b>{accountKey}</b>
                            </Text>
                            <Text ml={3} fontSize={"xs"} isTruncated color={"gray.500"} flexBasis={"25%"}>
                                Id da account: <b>{id}</b>
                            </Text>
                            <Flex fontSize={"xs"} w={"121px"}>
                                <Text mr={2}>Status:</Text>
                                {getBadge()}
                            </Flex>
                        </Flex>
                    </Box>
                </AccordionButton>
                <AccountReportButton auth={auth} id={id} accountKey={accountKey} />
            </Flex>
            <AccordionPanel>
                {(!accountDetails || loading) && (
                    <Flex h={"515px"}>
                        <Spinner alignSelf={"center"} margin={"0 auto"} color="yellow.400" speed={"1s"} borderLeftColor={"yellow.400"} thickness={"6px"} size={"100px"} h={"100px"} w={"100px"} />
                    </Flex>
                )}
                {accountDetails && !loading && (
                    <Flex px={2} bg={"white"} borderRadius={"12px"} w={"100%"} overflow={"hidden"}>
                        <Flex flexWrap={"wrap"} flexBasis={"73%"} w={"73%"}>
                            <Flex sx={{ gap: "12px" }} pr={3}>
                                <Flex flexDir={"column"}>
                                    <Text fontSize={"sm"}>Ciclo Inicial</Text>
                                    <InputGroup size={"sm"}>
                                        <Input disabled={isAccountClosed} type="number" pattern="[0-9]*" onBlur={(e) => changeAccountStartCycle(e)} onChange={(e) => changeStartCycle(e)} value={startCycle} w={"75px"} placeholder="" />
                                        {/* <InputRightAddon  children="Hrs" /> */}
                                    </InputGroup>
                                </Flex>
                                <Flex flexDir={"column"}>
                                    <Text fontSize={"sm"}>Ciclo Final</Text>
                                    <InputGroup size={"sm"}>
                                        <Input disabled={isAccountClosed} type="number" pattern="[0-9]*" onBlur={(e) => changeAccountEndCycle(e)} onChange={(e) => changeEndCycle(e)} value={endCycle} w={"75px"} placeholder="" />
                                        {/* <InputRightAddon children="Hrs" /> */}
                                    </InputGroup>
                                </Flex>
                                <Flex flexDir={"column"} minW={"70px"}>
                                    <Text fontSize={"sm"}>Ciclo Atual</Text>
                                    <Text lineHeight={"35px"} alignItems={"center"} fontSize={"sm"}>
                                        {accountDetails.atCycle}
                                    </Text>
                                </Flex>
                                <Flex flexDir={"column"}>
                                    <Text fontSize={"sm"}>Data de início</Text>
                                    <InputGroup className={"jiraDatePicker"} alignItems={"center"} pl={2} width={"160px"} size={"sm"} height="32px" border={"1px solid #e2e8f0"} fontSize={"sm"}>
                                        <DatePicker disabled={isAccountClosed} width={"160px"} dateFormat={"dd/MM/yyyy"} locale={ptBR} selected={getSelectedDate()} onChange={changeDate} />
                                    </InputGroup>
                                </Flex>
                                <Flex flexDir={"column"}>
                                    <Text fontSize={"sm"}>Squad</Text>
                                    <JiraSquadSelect {...{squadKey, setSquadKey,setSquadName}} accountKey={accountKey}/>
                                </Flex>
                            </Flex>
                            <Flex sx={{ gap: "12px" }}>
                                <Flex flexDir={"column"}>
                                    <Text fontSize={"sm"}>Tempo inicial</Text>
                                    <InputGroup size={"sm"}>
                                        <Input disabled={isAccountClosed} type="number" pattern="[0-9]*" size={"sm"} background={"white"} onBlur={(e) => changeAccountOffsetTime(e)} onChange={(e) => changeOffsetTime(e)} value={Number(offsetTime / 60) ?? 0} w={"70px"} />
                                        <InputRightAddon children="minutos" />
                                    </InputGroup>
                                </Flex>
                                <Flex flexDir={"column"}>
                                    <Text fontSize={"sm"}>Id RunRunIt</Text>
                                    <InputGroup size={"sm"}>
                                        <Input disabled={isAccountClosed} type="number" pattern="[0-9]*" size={"sm"} background={"white"} onBlur={(e) => changeAccountRunRunItId(e)} onChange={(e) => changeRunRunItId(e)} value={rriId ?? null} w={"132px"} placeholder="" />
                                    </InputGroup>
                                </Flex>
                                <Flex flexDir={"column"}>
                                    <Text fontSize={"sm"}>Account Manager</Text>
                                    <InputGroup size={"sm"} w={"240px"}>
                                        <Input disabled={isAccountClosed} type="email" size={"sm"} background={"white"} onBlur={(e) => changeAccountAccountManager(e)} onChange={(e) => changeAccountManager(e)} value={accountManager ?? null} maxW={"240px"} w={"100%"} placeholder="" />
                                    </InputGroup>
                                </Flex>
                                {/* <Flex flexDir={"column"}>
                                    <Flex alignItems={"center"}>
                                        <Text fontSize={"sm"} mr={2}>Limitar Acúmulo de Horas</Text>
                                        <Tooltip label="As horas acumuladas que serão carregadas para o próximo ciclo não podem exceder o valor de horas do ciclo vigente. Caso o valor acumulado ultrapasse o valor de horas do ciclo vigente, as horas excedentes serão removidas do valor acumulado.">
                                            <Box>
                                                <UilQuestionCircle size="16" />
                                            </Box>
                                        </Tooltip>
                                    </Flex>
                                    <InputGroup h={"32px"} alignItems={"center"}>
                                        <Switch onChange={(e) => changePurgeAccumulatedHours(e)} type="email" size={"md"} background={"white"} isChecked={accountDetails.purgeAccumulatedHours ?? false} maxW={"240px"} w={"100%"} placeholder="" />
                                    </InputGroup>
                                </Flex> */}
                            </Flex>
                            <Box overflow={"hidden"} border={"1px solid #f3f3f3"} borderRadius={"6px"} mt={3} p={2} pr={0} w={"100%"} h={"350px"}>
                                <Flex alignContent={"flex-start"} flexWrap={"wrap"} overflow={"auto"} w={"100%"} h={"100%"}>
                                    {accountCycles &&
                                        !!accountCycles.length &&
                                        !!accountDetails.endCycle &&
                                        !!accountDetails.startCycle &&
                                        new Array(accountDetails.endCycle - (accountDetails.startCycle - 1)).fill(0).map((_, index) => {
                                            let cycleNumber = index + accountDetails.startCycle;
                                            let cycleIndex = accountCycles.findIndex((cycle) => cycle.cycleNumber == cycleNumber);
                                            let cycle = accountCycles[cycleIndex];
                                            return <JiraAccountCycle key={"account-cycle-" + index} {...{ cycle, cycleIndex, accountCycles, setAccountCycles, cycleNumber, getTotalAndRemainingHours, isAccountClosed }} />;
                                        })}
                                </Flex>
                            </Box>
                            <Flex mt={2} mb={1} placeItems={"center"} justifyContent={"space-between"} w={"100%"}>
                                <Flex>
                                    <Text fontWeight={"bold"} pr={1}>
                                        Tempo total:
                                    </Text>
                                    <Text>{calculateTime(accountCycles)}</Text>
                                </Flex>
                                <Flex>
                                    <Text fontWeight={"bold"} pr={1}>
                                        Tempo atual:
                                    </Text>
                                    <Text>{calculateTime(accountCycles.filter((cycle) => cycle.cycleNumber <= accountDetails.atCycle))}</Text>
                                </Flex>
                                <Button disabled={isAccountClosed} size={"sm"} colorScheme={"yellow"} onClick={saveAccountData}>
                                    Salvar
                                </Button>
                            </Flex>
                        </Flex>
                        <Flex flexBasis={"27%"} w={"27%"} flexDir={"column"}>
                            <JiraAccountExtraTime {...{ accountCycles, accountDetails, setAccountCycles, isAccountClosed }} />
                            {isAccountClosed ? (
                                <Box ml={2} alignItems={"flex-end"} fontSize={"sm"} alignSelf={"flex-end"}>
                                    <Text fontSize={"sm"}>Account encerrado em:</Text>
                                    <Text fontWeight={"bold"}> {isAccountClosed.toLocaleDateString("pt-BR", { year: "numeric", month: "short", day: "numeric", timeZone: "UTC" })}</Text>
                                </Box>
                            ) : (
                                <AccountCloseProject {...{ onOpen, onClose, accountName: name, accountKey, setIsAccountClosed, setModalProps }} />
                            )}
                        </Flex>
                    </Flex>
                )}
            </AccordionPanel>
        </AccordionItem>
    );
};

export default JiraAccountAccordionItem;
