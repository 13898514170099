import React from 'react'
import { Box, Button, Text, Stack, Link,Image,Input,InputGroup,InputLeftElement,Checkbox, Skeleton, } from '@chakra-ui/react'
import { UilAngleRight,UilSearch } from '@iconscout/react-unicons'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Link as RRDLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PermissionContext } from '../../context/PermissionContext';
import { useParams,useLocation } from 'react-router';
import logo from '../../assets/logo-maeztra-menu.svg';
import { isAllowed } from '../../utils/functions'
import menuConfig from './ConfigSideBar' 
import permissionSchema from '../../utils/permissionSchema';

//0 Adm
//1 Externo
//3 Dev
//4 Produto
//5 Gerente
//6 Marketing
//7 Design
//8 RH
//9 GP
//10 Coordenador
const scrollStyle = {
    '&::-webkit-scrollbar':{
            'backgroundColor':'#CBD5E0',
            'width': '4px',
            'borderRadius':'6px',
        }
    ,
    '&::-webkit-scrollbar-thumb':{
        'backgroundColor':'#ECC94B',
        'borderRadius':'6px',
        }
}


const NewSideBar = ({activeLink,showFilter,filterProjectsByName,filterProjects}) => {
    const [sidebarIsOpen,setSidebarIsOpen] = React.useState(true)
    const [permissions, _] = React.useContext(PermissionContext);
    const { pageUrl } = useParams()
    const { pathname } = useLocation()
    const linkList = menuConfig.links
    let pnArr = pathname.split('/')
    let url = pageUrl?pageUrl:pnArr[pnArr.length-1]

    if(!permissions){
        return(
        <Box h='100%' bg={'white'} zIndex={100} boxShadow='base' transition={'0.2s'} padding={'16px 8px 0px 8px'} ml={sidebarIsOpen?0:'-255px'} height='100%' position='relative' flexBasis={sidebarIsOpen?'15%':'0'} minW='255px' top={0}>
            <Skeleton>
                <Link d={'flex'} alignItems={'center'} to={'/'} fontWeight={'500'} h={'40px'} mb={10}>
                </Link>
            </Skeleton>
            <Box my={7}>
                <Stack my={2} mx={2} spacing={5} justifyContent={'center'}>
            {[0,1,2,3,4,5].map((el,index)=>{
                return (
                    <Skeleton key={"skeleton-"+index}>
                        <Box h={'24px'}></Box>
                    </Skeleton>)
            })}
                </Stack>
            </Box>
        </Box>)
    }


    return (
        <Box h='100%' bg={'white'} zIndex={100} boxShadow='base' transition={'0.2s'} padding={'16px 8px 0px 8px'} ml={sidebarIsOpen?0:'-255px'} height='100%' position='relative' flexBasis={sidebarIsOpen?'15%':'0'} minW='255px' top={0}>
            <Link d={'flex'} alignItems={'center'} to={'/'} fontWeight={'500'} h={'40px'} mb={10}>
                <Image objectFit={'cover'} src={logo} />
            </Link>
            <Button size={'sm'} variant={'outline'} border={'none'} colorScheme={'white'} padding={0} backgroundColor={'#efefef'} position='absolute' right={'-20px'} top={'8px'} onClick={(e)=>setSidebarIsOpen(!sidebarIsOpen)}>
                <UilAngleRight size={16}></UilAngleRight>
            </Button>
            <Box overflow={'hidden'} height={'100%'} pb={2}>
                <Box sx={scrollStyle} overflow={'auto'} height={'calc(100% - 80px)'} pb={4}>
                    <Box >
                        <Stack my={2} spacing={1} justifyContent={'center'}>
                            {linkList.map((link,index)=>(
                                isAllowed(permissions,permissionSchema[link.url.replace(/\/*$/,'')])?
                                <React.Fragment key={'link-'+index}>
                                    <Link pl={1} background={link.url == (activeLink)?'#efefef':'inherit'} _hover={{'background':'#efefef'}} d={'flex'} alignItems={'center'} as={RRDLink} to={link.url} fontWeight={'500'} h={'40px'}>
                                        {link.icon} 
                                        <Text pl={'6px'}>{link.name}</Text>
                                    </Link>
                                    {link.url.includes(activeLink) && activeLink != "/" && link.subCategories &&
                                        link.subCategories.children.map((subLink,index)=>(
                                            isAllowed(permissions,permissionSchema[(link.url+subLink.url).replace(/\/*$/,'')])?
                                            <Link key={'sublink-'+link.url+'-'+index} pb={1} pl={10} to={link.url+subLink.url} as={RRDLink}>
                                                <Text mb={subLink.url == url?'-2px':0} borderBottom={subLink.url == url?'2px solid':''} borderColor={'yellow.400'} pl={0} w={'fit-content'} pr={4}>{subLink.name}</Text>
                                            </Link>:
                                            false
                                        ))
                                    }
                                </React.Fragment>:
                                false                        
                            ))}
                        </Stack>
                    </Box>
                    {showFilter &&
                    <React.Fragment>
                        <Box pb={7}>
                            <Text fontSize={'xs'} pb={3}>Pesquisar projeto</Text>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1em" children={<UilSearch size={'18'}/>}
                                />
                                <Input fontSize={'xs' }type="text" onChange={filterProjectsByName} placeholder="Digite o nome do projeto"></Input>
                            </InputGroup>
                        </Box>
                        <Box>
                            <Text textColor={'#B0B0B0'} fontSize={'sm'} fontWeight={'bold'} display={'inline-block'}><FontAwesomeIcon icon={faFilter} /> <Text pl={1} d={'inherit'}> Filtros</Text></Text>
                            <Stack spacing={4} py={4}>
                                <Text fontSize={'sm'}>Status:</Text>
                                <Checkbox fontSize={'sm'} value={'active'} onChange={(e) => filterProjects(e)}><Text fontSize={'sm'}>Ativo</Text></Checkbox>
                                <Checkbox value={'filed'} onChange={(e) => filterProjects(e)}><Text fontSize={'sm'}>Arquivado</Text></Checkbox>
                                <Checkbox value={'paused'} onChange={(e) => filterProjects(e)}><Text fontSize={'sm'}>Pausado</Text></Checkbox>
                                <Checkbox value={'closed'} onChange={(e) => filterProjects(e)}><Text fontSize={'sm'}>Encerrado</Text></Checkbox>
                            </Stack>
                            <Stack spacing={4}>
                                <Text fontSize={'sm'}>Squad:</Text>
                                <Checkbox value={'bateria'} onChange={(e) => filterProjects(e)}><Text fontSize={'sm'}>Bateria</Text></Checkbox>
                                <Checkbox value={'contrabaixo'} onChange={(e) => filterProjects(e)}><Text fontSize={'sm'}>Contrabaixo</Text></Checkbox>
                                <Checkbox value={'piano'} onChange={(e) => filterProjects(e)}><Text fontSize={'sm'}>Piano</Text></Checkbox>
                                <Checkbox value={'violao'} onChange={(e) => filterProjects(e)}><Text fontSize={'sm'}>Violão</Text></Checkbox>
                                <Checkbox value={'violino'}onChange={(e) => filterProjects(e)}><Text fontSize={'sm'}>Violino</Text></Checkbox>
                            </Stack>
                        </Box>
                    </React.Fragment>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export default NewSideBar
