import { useState,useEffect,useContext } from 'react'
import { FormControl,FormLabel, Input, Flex, Box, Container,Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton} from "@chakra-ui/react"
import { AuthContext } from '../../context/AuthContext';
import { Button } from '@chakra-ui/button'
import { FormErrorMessage } from '@chakra-ui/form-control'
import { useToast } from "@chakra-ui/react"
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import api from '../../services/authApi'

const schema = yup.object().shape({
    name: yup.string().required('Nome completo é obrigatório'),
    email: yup.string().email('Insira um e-mail válido').required('E-mail completo é obrigatório')
})

const toastUserAdded = {
    title: "Usuário adicionado.",
    status: "success",
    duration: 2000,
    isClosable: true,
    position:"top"
}
const toastUserAddedError = {
    title: "Ocorreu um erro ao adicionar o usuário.",
    status: "error",
    duration: 2000,
    isClosable: true,
    position:"top"
}

const NewUser = ({users,setUsers,isOpenNewUser,onCloseNewUser}) => {
    const toast = useToast()
    const [auth, setAuth] = useContext(AuthContext);
    const [btnSendIsLoading,setBtnSendIsLoading] = useState(false)
    const { register, handleSubmit,reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    })

    useEffect(()=>{
        if(auth){
            api.defaults.headers.common['google-token'] = auth.accessToken
        }
    },[auth])

    const onSubmit = async (data) => {
        try {
            setBtnSendIsLoading(true)
            api.post('/createUser', data).then((res)=>{
                let newUsers = [...users]
                newUsers.unshift({
                    user_id:res.data.result.insertId,
                    user_name:data.name,
                    permission_ids: []
                })
                setUsers(newUsers)
                onCloseNewUser()
                reset()
                toast(toastUserAdded)
            }).catch((err)=>{
                toast(toastUserAddedError)
                if(err.message.error){
                    setAuth(null)
                }
            }).finally(()=>{
                setBtnSendIsLoading(false)
            })

        } catch (error) {
            console.error(error)
        }
    }

    const errorProperties = (errors) => !!errors && {
        isInvalid: true,
        errorBorderColor: "crimson"
    }
    

    return (
        <Modal isOpen={isOpenNewUser} onClose={onCloseNewUser} >
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>Adicionar usuário</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                <Flex direction='row' h={'100%'} fontFamily={'Roboto'}>
                    <Box flexBasis='100%' pt={3} overflow={'auto'}>
                        <Container maxW="100%" bgColor="white" p={0}>                    
                            <form method="POST" onSubmit={handleSubmit(onSubmit)}>        
                                <FormControl id="register" d={'flex'} flexWrap="wrap" pb={4}>
                                    <Box flexBasis={'100%'} pb={4}>
                                        <FormLabel htmlFor="name">Nome</FormLabel>
                                        <Input
                                            type="text" 
                                            focusBorderColor="initial"
                                            {...register('name')}
                                            {...errorProperties(errors.name)}
                                        />
                                        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                                    </Box>
                                    <Box flexBasis={'100%'}>
                                        <FormLabel htmlFor="email">E-mail</FormLabel>
                                        <Input 
                                            type="text" 
                                            focusBorderColor="initial"
                                            {...register('email')} 
                                            {...errorProperties(errors.email)}
                                        />
                                        <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                                    </Box>
                                </FormControl >                                
                            </form>
                        </Container>
                    </Box>
                </Flex>
                </ModalBody>

                <ModalFooter>
                    <Button mr={3} onClick={onCloseNewUser}>
                    Fechar
                    </Button>
                    <Button colorScheme={"yellow"} isLoading={btnSendIsLoading} onClick={handleSubmit(onSubmit)} type="submit">Enviar</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default NewUser

