import Holidays from 'date-holidays'

export function calcHours (seconds){
    let isNegative = false;
    if (!seconds) return '';
    if(seconds == 0) return '00:00:00'
    if(seconds<0){
        seconds=seconds*-1
        isNegative=true;
    }    
 
    let duration = seconds;
    let hours = duration / 3600;
    duration = duration % (3600);
   
    let min = parseInt(duration / 60);
    duration = duration % (60);
   
    let sec = parseInt(duration);
   
    if (sec < 10) {
      sec = `0${sec}`;
    }
    if (min < 10) {
      min = `0${min}`;
    }
    let negative = isNegative?'-':'';
    let parIntH = parseInt(hours, 10)
    if ( parIntH> 0) {
      return `${negative}${parIntH<10?'0':''}${parIntH}:${min}:${sec}`
    }
    else if (min == 0) {
      return `${negative}00:00:${sec}`
    }
    else {
      return `${negative}00:${min}:${sec}`
    }
}

export function isAllowed(permissions,allowedArr){

    //colocar func para pegar as permissões aqui depois
    //as permissoes estão escritas no componente+no arquivo de links
    //fazer só pegar do arquivo de link

    if(!allowedArr || !allowedArr.length){
        return true
    }else{
        let intersec = allowedArr.filter(value => permissions.includes(String(value)));
        return intersec.length?true:false
    }        
}
 
export function getStartDate(){
    try{
        let lsDate = localStorage.getItem('chartStartDate');
        if(lsDate){
            return lsDate
        }else{
            let date = new Date();
            let startMonthDate = new Date(date.getFullYear(), date.getMonth(), 1);
            localStorage.setItem('chartStartDate',startMonthDate.toISOString());
            return 
        }
    }catch(e){
        localStorage.removeItem('chartStartDate')
    }
}

export function getEndDate(){
    try{
        let lsDate = localStorage.getItem('chartEndDate');
        if(lsDate){
            return lsDate
        }else{
            let date = new Date();
            localStorage.setItem('chartEndDate',date.toISOString());
            return 
        }
    }catch(e){
        localStorage.removeItem('chartEndDate')
    }
}

export function getLSBackofficeValue() {
    try {
        let boValue = localStorage.getItem('backofficeValue');
        if (boValue && !isNaN(boValue)) {
            return boValue
        } else {        
            localStorage.setItem('backofficeValue',15);
            return 15
        }
    } catch (e) {
        localStorage.removeItem('backofficeValue')
        return 15
    }
}

export function setLSBackofficeValue(value){
    if(isNaN(value)){
        localStorage.setItem('backofficeValue',15);
        return
    }
    localStorage.setItem('backofficeValue',value);

}

export function getIfWeekendOrHoliday(date) {
    const isWeekend = new Date(date).getDay() !== 0 && new Date(date).getDay() !== 6
    if (!isWeekend) {
        return false
    }
    const stateAndCityHolidays = [
        { month:1, day:25, name:'Aniversário de São Paulo' },
        { month:5, day:30, name:'Corpus Christi' },
        { month:7, day:9, name:'Revolução Constitucionalista' }
    ]
    const holidaysToIgnore = [ 
        { month: 2, day: 14, name: 'Cinzas' },
        { month: 6, day: 12, name: 'Dia dos Namorados'}
    ]
    //check if brazilian holiday. can use lib
    let dateLocalized = new Date(date)
    const holidays = new Holidays('BR', 'pt-BR');
    //subtract 3 hours to get the right date
    dateLocalized.setHours(dateLocalized.getHours() - 3)
    const isHoliday = holidays.isHoliday(dateLocalized)
    
    const isHolidayToIgnore = holidaysToIgnore.some(holiday => holiday.month === dateLocalized.getMonth() + 1 && holiday.day === dateLocalized.getDate())
    if(isHolidayToIgnore) return true

    const isHolidayStateAndCity = stateAndCityHolidays.some(holiday => holiday.month === dateLocalized.getMonth() + 1 && holiday.day === dateLocalized.getDate())
    if (isHolidayStateAndCity) return false

    if (isHoliday) return false
    
    return true

}