import React from "react";
import { closeAccount } from "../../services/jira";
import { AuthContext } from "../../context/AuthContext";
import { Flex, InputGroup, IconButton, Text, ModalCloseButton, Button, ModalOverlay, ModalContent, Modal, ModalBody, ModalFooter, ModalHeader, useToast } from "@chakra-ui/react";
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

registerLocale("br", ptBR);

const toastSuccess = (accountName) => {
    return {
        title: `Account ${accountName} encerrado`,
        duration: 3000,
        status: "success",
        isClosable: true,
        position: "top",
    };
};

const toastFailure = ( accountName ) => {
    return {
        title: `Não foi possível encerrar o Account ${accountName}`,
        duration: 3000,
        status: "error",
        isClosable: true,
        position: "top",
    };
};

const CloseAccountModal = ({ isOpen, onClose, modalProps, setModalProps }) => {
    const [auth] = React.useContext(AuthContext);
    const toast = useToast();
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [accountName, setAccountName] = React.useState("");
    const [accountKey, setAccountKey] = React.useState("");
    const [loading,setLoading] = React.useState(false)


    React.useEffect(() => {
        setAccountName(modalProps.accountName);
        setAccountKey(modalProps.accountKey);
    }, [modalProps.accountName,modalProps.accountKey]);

    function handleClick() {
        if (!selectedDate) return;
        closeAccount(auth.accessToken,accountKey,selectedDate.toISOString())
        .then(()=>{
            toast(toastSuccess(accountName));
            onClose();
            modalProps.setIsAccountClosed(selectedDate);
            setModalProps({});
        })
        .catch(()=>{
            toast(toastFailure(accountName));
        }).finally(()=>{
            setLoading(false)
        })
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Encerramento de ciclos do Account {accountName} - {accountKey}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text fontWeight={"bold"} fontSize={"sm"}>
                        Atenção: Essa ação é irreversível.
                    </Text>
                    <Text fontSize={"sm"} mb={4}>
                        Ao encerrar um projeto as horas restantes permanecem, mas novos ciclos não serão abertos
                    </Text>
                    <Flex flexDir={"row"} alignItems={"center"}>
                        <Text mr={3} fontWeight={"bold"} fontSize={"sm"}>
                            Data de encerramento:
                        </Text>
                        <InputGroup className={"jiraDatePicker"} alignItems={"center"} pl={2} width={"160px"} size={"sm"} height="32px" border={"1px solid #e2e8f0"} fontSize={"sm"}>
                            <DatePicker width={"160px"} dateFormat={"dd/MM/yyyy"} selected={selectedDate} locale={ptBR} onChange={setSelectedDate} />
                        </InputGroup>
                    </Flex>
                </ModalBody>

                <ModalFooter>
                    <Button loading={loading} size={"sm"} disabled={!selectedDate} colorScheme="yellow" mr={3} onClick={handleClick}>
                        Confirmar Encerramento
                    </Button>
                    <Button size={"sm"} variant="ghost" onClick={onClose}>
                        Cancelar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CloseAccountModal;
