import React,{useState,useContext} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from 'react-multi-date-picker'
import { faTimes, faChevronRight,faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { DateRangeContext } from '../context/DateRangeContext';
import normalizeDate from '../utils/normalizeDate';
import convertDecimalToTime from '../utils/convertDecimalToTime';
import { patchIssueJira } from '../../../services/jira';
import { formatDate } from '../utils/formatDate';
import timeToDecimal from '../utils/convertTimeToDecimal';
import { UserTasks } from '../context/userTasks';
import gregorian_pt_br from "react-date-object/locales/gregorian_pt_br";
import { AuthContext } from '../../../context/AuthContext';

export default function CustomTimeModal({position, task, setModalOpen, modalOpen}) {
    const [,,datesBetween ] = useContext(DateRangeContext)
    const [isOpen,setIsOpen]=useState(false)
    const [fadeOut,setFadeOut]=useState(false)
    const [distributeHoursOpen,setDistributeHoursOpen]=useState(false)
    const initialDate = task.fields.customfield_10022?normalizeDate(task.fields.customfield_10022):datesBetween[0]?.dateDataBase || new Date()
    const finalDate = task.fields.customfield_10023? normalizeDate(task.fields.customfield_10023):datesBetween[datesBetween.length-1]?.dateDataBase || new Date()
    const [newInitial,setNewInitial]= useState(initialDate)
    const [newFinal,setNewFinal]= useState(new Date(finalDate))
    const [newHours,setNewHours]= useState(task.fields.timeoriginalestimate/3600)
    const [errorMessage,setErrorMessage]=useState('')
    const [,,,,taskReq,,updateIssue]=useContext(UserTasks)
    const [loading,setLoading]=useState(false)
    const [keyReseter,setKeyReseter]=useState(0);
    const [auth] = React.useContext(AuthContext);

    function toggleDistributeHours(e){
        setDistributeHoursOpen(!distributeHoursOpen)
    }
    function closeModal(){
        setFadeOut(true)
        setTimeout(()=>{
            setIsOpen(false)
            if(modalOpen&&setModalOpen){setModalOpen(false)}
            setFadeOut(false)
        },500)        
    }

    
    function patchJiraHandler (data,issueKeyOrId){
        return new Promise((resolve,reject)=>{
            patchIssueJira(auth.accessToken,data,issueKeyOrId).then((response) => {
                return resolve(response)
            }).catch(e=>reject(e))
        })
    }
    async function handleSubmit(){
        if(!newInitial||!newFinal||!newHours)return;
        const floatNewHours = parseFloat(newHours)
        if(isNaN(floatNewHours)) return setErrorMessage("Invalid hours")
        if(new Date(newInitial)>new Date(newFinal)){
            setErrorMessage('initial date > final date!')
            return
        }
        setLoading(true)
        const data = {
            fields: {
                customfield_10022:formatDate(new Date(newInitial)).dateDataBase, 
                customfield_10023:formatDate(new Date(newFinal)).dateDataBase,
                timetracking: { originalEstimate:floatNewHours}  
            }
        }
        await patchJiraHandler(data,task.id)
        setLoading(false)
        updateIssue(task.key,data.fields.customfield_10022,data.fields.customfield_10023,floatNewHours*3600)
        setErrorMessage('')
        setIsOpen(false)
    }

    function handleCancel(){
        setKeyReseter(keyReseter+1)
        setNewHours('')  
        setErrorMessage('')
        closeModal()
    }

    const parseInputValue = e =>{
        const value = e.target.value;

        // Validate if input is a valid number or dot
        if (/^\d*\.?\d*$/.test(value)) {
            setNewHours(value);
        } else {
          // If not a valid input, revert to the previous value
          setNewHours(newHours);
        }
    }

    return (
        
        <div className={`CustomTimeModal position-absolute top-0 start-${position%2===0?'50':'0'}`}>
               {!setModalOpen && <button onClick={()=>setIsOpen(true)} className='editTime'></button>}
                {(isOpen ||modalOpen) &&
                    <div className={`modalBack ${fadeOut?'fadeOut':''}`} id='modalBack' onClick={(e)=>e.target.id==='modalBack'&&closeModal()}>
                        <div className='modalBox '>
                            <div className=' CustomTimeModalHead'>
                                <p>Editar planejamento {task.key}</p>
                                <button   id='closeButtom'  onClick={()=>closeModal()}> 
                                <FontAwesomeIcon icon={faTimes}/>
                                </button>
                            </div>
                           <Row key={keyReseter}>
                                <Col>
                              
                                    <p className='text-gray700 mb-1'>Estimativa tarefa</p>
                                    <InputGroup className={`mb-3  text-gray600 ${errorMessage.indexOf('hours')>-1?'errorBorder':''}`} >
                                        <Form.Control
                                            className='border-end-0  text-gray600 without_ampm'
                                            placeholder="0.00"
                                            aria-label="0.00"
                                            aria-describedby="basic-addon2"
                                            value={newHours}
                                            defaultValue={newHours}
                                            onChange={(e)=>parseInputValue(e)}
                                            type="tel"
                                        />
                                        <InputGroup.Text id="basic-addon2" className='bg-gray200 text-gray600' >horas</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <p  className='text-gray700 mb-1'>Data início</p>
                                    <div className={errorMessage.indexOf('date')>-1?'errorBorder':''} >
                                        <DatePicker format={"DD/MM"} locale={gregorian_pt_br} value={initialDate} onChange={setNewInitial}/>
                                    </div>

                                </Col>
                                <Col>
                                    <p  className='text-gray700 mb-1'>Data final</p>
                                    <div className={errorMessage.indexOf('date')>-1?'errorBorder':''} >
                                        <DatePicker format={"DD/MM"} locale={gregorian_pt_br} value={finalDate} onChange={setNewFinal} />
                                    </div>
                                </Col>
                            </Row>
                            <div className='distributeHours'>
                                <div className='distributeHeader' onClick={toggleDistributeHours}>
                                    <FontAwesomeIcon icon={distributeHoursOpen?faChevronUp:faChevronRight}/>
                                    <p>Distribuir horas nos dias definidos</p>
                                </div>
                            {distributeHoursOpen&&<div className='distributeHoursInputs ' >
                                    {datesBetween.map((day,index)=>
                                        <div key={index}>
                                            <p className='mb-1 text-gray600 '>{day.formatedDate}</p>
                                            <InputGroup className="mb-3">
                                                <Form.Control
                                                className='border-end-0  text-gray600'
                                                placeholder="0"
                                                aria-label="0"
                                                aria-describedby="basic-addon2"
                                                defaultValue={day.date>=initialDate&&day.date<=finalDate&&day.isWeekDay?task.hoursPerDay:0}
                                                />
                                                <InputGroup.Text id="basic-addon2" className='bg-gray200 text-gray600'>horas</InputGroup.Text>
                                            </InputGroup>    
                                        </div>
                                    )}
                                </div>}
                            </div>
                            <div className='d-flex justify-content-end mt-3'>

                        <button className='btn cancelBtn' onClick={handleCancel}>Cancelar</button>
                        <button onClick={ handleSubmit} className='btn saveBtn' style={loading?{pointerEvents:"none"}:null}>
                         
                            {loading?
                                <div  className='loader'>
                                    <div class="spinner-border" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            :
                                'Salvar'
                            }
                            </button>
                            </div>
                        {errorMessage!=''?<p className='errorMessage my-2 mx-1'>{errorMessage}</p>:null}
                        </div>

                    </div>
                }
            </div>
    )
}



