import React,{useState,useContext, useRef} from 'react'
import {  Row } from 'react-bootstrap'
import { UserFilterContext } from './context/UserFilterContext';
import { DateRangeContext } from './context/DateRangeContext';
import UserRow from './PlanningContent/UserRow'
import TaskList from './PlanningContent/TaskList'
import { UserTasksProvider } from './context/userTasks';
import { GroupingTypeContext } from './context/GroupingTypeContext';
import ScrollCheck from './utils/ScrollCheck';

export default function PlannerContent({disabledEdit}) {
    const [usersToShow,_,loading] = useContext(UserFilterContext)
    const [groupingType] = useContext(GroupingTypeContext)
    const [,,datesBetween ] = useContext(DateRangeContext)
    const [openList,setOpenList]=useState([])
    const containerRef = useRef()
    const headerRef =  useRef()
    
 
    function handleOpen(employeeIndex){
        const index = openList.indexOf(employeeIndex);
        if(index>-1){
           const newList =  openList.filter(item=>item!==employeeIndex);
           setOpenList( newList)
        }
        else{
            setOpenList([...openList,employeeIndex])
        }
    }

    React.useEffect(()=>{ 
        setOpenList([])
    },[usersToShow])
 
    return (
        // row aqui só serve pra dar match com o header
        <Row>
            <div className='informative mt-4'>
                <strong className="text-bold">As tarefas com os status "Open","Validação QA","Validação Interna" e "Impeditivo" não serão consideradas</strong>
            </div>
            <div className="border-radius bg-gray300 plannerContent mt-4">
                <div>
                    <div className="plannerHead  text-gray600 m-0 d-flex">
                        <div className='leftCol '>
                            <p>Equipe</p>
                            <p className='text-right'>Tempo</p>
                        </div>
                        {datesBetween[0]? <p  className='rightCol text-center'>{datesBetween[0].formatedDate.split(" ")[1]} a {datesBetween[1].formatedDate.split(" ")[1]}</p>:null}
                    </div>
                    <div className="border-radius bg-gray300 overflow-y-auto overflow-x-auto plannerContentHolder " ref={containerRef} >
                        <div  className="border-top-radius  bg-gray100  m-0 employeeBox">
                            <div className="text-gray600 plannerWeekDays d-flex">
                                <div  className='leftCol'></div>
                                <div className='colWeekDays' >
                                    <div className='weekDays d-flex' ref={headerRef}>
                                        {datesBetween.map((day,index)=><div className={`${day.isWeekDay?'':'weekendDay'} ${groupingType}`} key={index}>{day.formatedDate}</div>)}     
                                    </div>
                                </div>
                            </div>
                            {usersToShow?.length>0 &&
                            usersToShow?.map((employee,index)=>
                                <div className={`${openList.includes(index)?"bg-gray300":"bg-gray100"} ml-2 userRowWrapper`} key={index}>
                                    <UserTasksProvider >
                                        <UserRow index={index} openList={openList} employee={employee} handleOpen={handleOpen} />
                                            {openList.includes(index)&&
                                                <TaskList {...{disabledEdit}} employeeId={employee.accountId}/>                                    
                                            }
                                    </UserTasksProvider>
                                </div>
                            )}
                        </div>
                        {loading&&<div className="TaskRowLoadingContainer"><div className="TaskRowLoading"></div></div>}
                    </div>
                </div>
            </div>
            <ScrollCheck {...{headerRef,containerRef}}/>
        </Row>
    )
}
