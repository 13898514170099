import React,{ useContext } from 'react';
import {DateRangeContext} from '../context/DateRangeContext'
import { GroupingTypeContext } from '../context/GroupingTypeContext';
import convertDecimalToTime from '../utils/convertDecimalToTime'

export default function UsedHours({dateAndHours}) {
  const [,,datesBetween ] = useContext(DateRangeContext);
  const [groupingType ] = useContext(GroupingTypeContext);
    
    function hourStatus(hours){
      if (hours >= 6 && hours <= 8)   return 'green';
      else if (hours < 6)             return 'yellow';
      else if (hours > 8)             return 'red';
    }
    
  return (
    <div  className='  d-flex align-items-center text-center hoursByDayWrapper'>
        {datesBetween.map((day,index)=>
            {
            const hours = dateAndHours[day.formatedDate]
              return  <div key={index}  className={` d-flex align-items-center text-center justify-content-center hoursByDay ${day.isWeekDay?"":"weekendHourBox"} ${groupingType}`}> 
                        {                          
                          day.isWeekDay?
                          <>
                            <span className={`timeStatus ${hourStatus(hours?hours:0)}`}></span> {hours?convertDecimalToTime(Math.ceil(hours*100)/100):0}h
                          </>:
                          <span className='weekendHours'>--</span>
                        }
                          
                      </div>})
            }                    
    </div>
 
  )
}
