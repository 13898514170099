
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Box,
} from '@chakra-ui/react'
import { percentageToText, secondsToHours } from '../PerformanceContent'
import React from 'react'

export default function TotalHoursDetails({ issues }) {

    return <Box maxH={'350px'} overflow={'auto'}>
        <TableContainer>
            <Table variant='striped' >
                <Thead>
                    <Tr>
                        <Th>Key</Th>
                        <Th>Link da Tarefa</Th>
                        <Th>Horas Consumidas</Th>
                        <Th>Horas Estimadas</Th>
                        <Th>Taxa de Assertividade</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Object.keys(issues).map((key, index) => (
                        <Tr key={`assertivity-row-${key}-${index}`}>
                            <Td py={2}>{key}</Td>
                            <Td _hover={{ "textDecor": "underline" }} py={2}><a href={`https://maeztra.atlassian.net/browse/${key}`} target='blank'>https://maeztra.atlassian.net/browse/{key}</a></Td>
                            <Td py={2}>{secondsToHours(issues[key].timeUsed)}</Td>
                            <Td py={2}>{secondsToHours(issues[key].timeEstimate)}</Td>
                            <Td py={2}>{percentageToText((issues[key].timeEstimate / issues[key].timeUsed))}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    </Box>

}