import React, { useCallback } from "react";
import { Flex, Box, Accordion, Skeleton,useDisclosure, useToast } from "@chakra-ui/react";
import _ from 'lodash'
import { Paginator, Container, Previous, Next, PageGroup } from "chakra-paginator";
import { PermissionContext } from "../../context/PermissionContext";
import permissionSchema from "../../utils/permissionSchema";
import SearchInput from "../SearchInput";
import { isAllowed } from "../../utils/functions";
import JiraAccountAccordionItem from "./JiraAccountAccordionItem";
import CloseAccountModal from "./CloseAccountModal";
import {invalidTokenToast} from "../../utils/toasts"
import { AuthContext } from "../../context/AuthContext";
import { getAllJiraProjects } from "../../services/jira";

const baseUrl = process.env.NODE_ENV === "development" ? "//localhost:3009" : "https://robocop.maeztra.com";

const baseStyles = {
    w: 7,
    fontSize: "sm",
    height: "28px",
};

const normalStyles = {
    ...baseStyles,
    width: "30px",
    _hover: {
        bg: "yellow.300",
    },
    bg: "gray.200",
};

const activeStyles = {
    ...baseStyles,
    _hover: {
        bg: "yellow.500",
    },
    bg: "yellow.400",
};

const separatorStyles = {
    w: 7,
    bg: "gray.200",
    height: "28px",
};

const JiraAccount = () => {
    const toast = useToast()
    //modal disclosure
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [modalProps,setModalProps] = React.useState({
        accountName:"",
        accountKey:"",
        setIsAccountClosed:null
    })

    const perPage = Math.floor((window.innerHeight - 150) / 64 - 1);
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pagesQuantity, setPageQuantity] = React.useState(0);

    const [accountsList, setAccountsList] = React.useState([]);
    const [sortedAccountsList, setSortedAccountsList] = React.useState([]);
    const [searchText, setSearchText] = React.useState("");
    const debounceFn = useCallback(_.debounce(filterAccountsDebounce, 300), [accountsList]);

    const [loading, setLoading] = React.useState(true);

    //auth
    const [permissions] = React.useContext(PermissionContext);
    const [allowed] = React.useState(permissionSchema[window.location.pathname.replace(/\/*$/, "")] || [0]);
    const [notAllowed, setNotAllowed] = React.useState();

    const [openAccordions, setOpenAccordions] = React.useState(-1);
    const [auth] = React.useContext(AuthContext);

    React.useEffect(() => {
        if (permissions) {
            setNotAllowed(!isAllowed(permissions, allowed));
        }
    }, [permissions]);

    React.useEffect(() => {
        getAllJiraProjects(auth.accessToken) 
            .then(({ data:{results} }) => {
                setLoading(false);
                let len = results.length;
                let pagesQuantity = Math.ceil((len || 1) / perPage);
                setPageQuantity(pagesQuantity);
                setAccountsList(results);
            })
            .catch((e) => {
                toast(invalidTokenToast)
                console.warn(e);
            })           
    }, []);

    function handleSearchInput(e) {
        let value = e.target.value;
        debounceFn(value);
    }

    function filterAccountsDebounce(value) {
        setSearchText(value);
        setSelectedPage(1)
        let accs = accountsList.filter((account) => {
            if (account.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(value.toLowerCase())) return true;
        });
        let pagesQuantity = Math.ceil(((accs && accs.length) || 1) / perPage);
        setOpenAccordions(-1)
        setPageQuantity(pagesQuantity);
        setSortedAccountsList(accs);
    }

    function getPage() {
        let list;
        if (searchText) {
            if (sortedAccountsList.length) {
                list = getCurrentPageRows(sortedAccountsList);
            } else {
                list = [];
            }
        } else {
            list = getCurrentPageRows(accountsList);
        }
        return list || [];
    }

    function getCurrentPageRows(arr) {
        let newArr = JSON.parse(JSON.stringify(arr));

        let a = perPage * (selectedPage - 1);
        let b = perPage * selectedPage;
        return newArr.slice(a, b);
    }

    function handlePageChange(page) {
        setSelectedPage(page);
    }

    if (notAllowed) {
        return null;
    }
    return (
        <Box flexBasis="100%" overflow={"auto"} pb={6}>
            {/* <DatePickerHeader {...{ startDate, setStartDate, endDate, setEndDate }} /> */}

            <Box maxW={"1600px"} w={"auto"} mx={"4%"} pt={"20px"}>
                <Flex mb={3} sx={{ gap: "16px" }} alignItems={"center"}>
                    <SearchInput isDisabled={loading} onChangeFunction={handleSearchInput} />
                    <Flex flex={1} flexDirection={pagesQuantity > 1 ? "row" : "row-reverse"} justifyContent={"space-between"}>
                        {pagesQuantity > 1 && (
                            <Flex maxW={"400px"}>
                                <Paginator currentPage={selectedPage} pagesQuantity={pagesQuantity} activeStyles={activeStyles} normalStyles={normalStyles} separatorStyles={separatorStyles} innerLimit={3} outerLimit={3} onPageChange={(e) => handlePageChange(e)}>
                                    <Container align="flex-end" justify="space-between" pt={4} pb={0}>
                                        <Previous _hover={{ bg: "yellow.300" }} bg="gray.200" mr={1} h={"28px"}>
                                            {"<"}
                                            {/* Or an icon from `react-icons` */}
                                        </Previous>
                                        <PageGroup height={"28px"} isInline align="center" />
                                        <Next _hover={{ bg: "yellow.300" }} bg="gray.200" ml={1} h={"28px"} w={"30px"}>
                                            {">"}
                                            {/* Or an icon from `react-icons` */}
                                        </Next>
                                    </Container>
                                </Paginator>
                            </Flex>
                        )}
                    </Flex>
                </Flex>
                {loading?
                (new Array(perPage || 6)).fill(0).map((_,index)=>(
                    <Skeleton key={"account-skeleton-"+index} w={"100%"} minH={"53px"} borderRadius={"12px"} mb={3}/>
                ))
            : <Accordion index={openAccordions} allowToggle onChange={(e) => setOpenAccordions(e)} sx={{ overflowAnchor: "auto;" }} minHeight={"52px"}>
                    {getPage().map((account, index) => {
                        account.accountKey = account.key
                        let isOpen = openAccordions == index? true : false;
                        return <JiraAccountAccordionItem key={"jira-account-"+index} {...{ ...account, isOpen,onOpen,setModalProps }} />;
                    })}
                </Accordion>
            }
            <CloseAccountModal  { ...{isOpen, onOpen, onClose,modalProps,setModalProps} } />
            </Box>
        </Box>
    );
};

export default JiraAccount;
