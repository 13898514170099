import axios from 'axios'

const baseURL = process.env.NODE_ENV == "development" ? '//localhost:2999/api' :'https://pass.maeztra.com/api'

const api = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    }
})

export default api