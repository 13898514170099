import { UilAirplay } from '@iconscout/react-unicons'
//0 Adm
//1 Externo
//3 Dev
//4 Produto
//5 Gerente
//6 Marketing
//7 Design
//8 RH
//9 GP
//10 Coordenador
//14 Coordenador +
export default {
    links: [
        { name: 'Dashboard Geral', icon: <UilAirplay size={'20px'} style={{ 'display': 'inline-block' }} />, url: "/" },
        { name: 'Jira', icon: <UilAirplay size={'20px'} style={{ 'display': 'inline-block' }} />, url: "/jira-accounts/" },
        {
            name: 'Permissões', icon: <UilAirplay size={'20px'} style={{ 'display': 'inline-block' }} />, url: "/permission-management/",
            subCategories: {
                parent: '/permission-management/',
                children: [
                    // {url:'management',name:'Permissões',allowed:[0]}
                ]
            }
        },
        { name: 'Planning', icon: <UilAirplay size={'20px'} style={{ 'display': 'inline-block' }} />, url: "/planning" },
        //Performance
        { name: 'Performance', icon: <UilAirplay size={'20px'} style={{ 'display': 'inline-block' }} />, url: "/performance" },
        { name: 'Usuários Ponto/Jira', icon: <UilAirplay size={'20px'} style={{ 'display': 'inline-block' }} />, url: "/userRelations" },

    ]
} 
