import React from 'react'
import PlannerFilters from './PlannerFilters';
import PlannerContent from './PlannerContent';
import { Container } from 'react-bootstrap';
import { GroupingTypeProvider } from './context/GroupingTypeContext';
import { DateRangeProvider } from './context/DateRangeContext';
import { UserFilterProvider } from './context/UserFilterContext';
import { HideIncompleteTextProvider } from './context/HideIncompleteTextContext';
import { isAllowed } from '../../utils/functions';

export default function Planning({isLeastPermission}) {

    return (
        <HideIncompleteTextProvider>
            <UserFilterProvider>
                <DateRangeProvider>
                    <GroupingTypeProvider>
                        <Container direction='row' h={'100%'} fontFamily={'Roboto'}>
                            <div flexBasis='100%' mr={0} ml={'auto'} px={'4%'} pt={3} overflow={'auto'}>
                                <Container w={'auto'} maxW={'100%'}>
                                    <PlannerFilters hideFilters={isLeastPermission} />
                                    <PlannerContent disabledEdit={isLeastPermission}/>
                                </Container>
                            </div>
                        </Container>
                    </GroupingTypeProvider>
                </DateRangeProvider>
            </UserFilterProvider>
        </HideIncompleteTextProvider>
    )
}
