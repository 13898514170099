//tooltip that receives the text to show as parameters
//the tooltip is an info icon that shows a text when hovered
//position absolute is used to position the tooltip

import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function TooltipComponent({ text }) {
    return (
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id={`tooltip-top`}>
                    {text}
                </Tooltip>
            }
        >
            <span className='text-gray600 mz-tooltip'><FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon></span>
        </OverlayTrigger>
    )
}
