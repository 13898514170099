exports.invalidTokenToast = {
    title:"Token expirado",
    description:"Por favor, atualize a página",
    duration:3000,
    status:"error"
}

exports.hoursNotFilled = {
    title: "Preencha as horas de todos os ciclos",
    duration: 3000,
    status: "error",
    isClosable: true,
    position: "top",
}

exports.accountSavedSuccess ={
    title: "Projeto salvo com sucesso",
    duration: 2000,
    status: "success",
    isClosable: true,
    position: "top",
}
exports.accountSavedFailure={
    title: "Não foi possível salvar as alterações",
    duration: 2000,
    status: "error",
    isClosable: true,
    position: "top",
}