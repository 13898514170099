import React, { useContext } from 'react'
import { Col } from 'react-bootstrap'
import DatePicker from 'react-multi-date-picker'
import gregorian_pt_br from "react-date-object/locales/gregorian_pt_br";
import { DateRangeContext } from '../context/DateRangeContext';

const DateSelector = () => {
    const calendarRef = React.useRef(null);
    const [calendarIsOpen, setCalendarIsOpen] = React.useState(false)
    const [dateValues, setDateValues] = useContext(DateRangeContext);
  return (
    <Col className="d-flex flex-row gap-4 col-2 justify-flex-start">
        <div ref={calendarRef} onClick={() => setCalendarIsOpen(!calendarIsOpen)} className="d-flex flex-row gap-1 bg-white b-1 p-2 h-10 rounded max-w-120 overflow-hidden">
            <DatePicker editable={false} sort rangeHover onChange={setDateValues} format={"DD/MM"} locale={gregorian_pt_br} range={true} value={dateValues} numberOfMonths={2} />
        </div>
    </Col>
  )
}

export default DateSelector