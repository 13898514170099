import axios from 'axios';

const baseUrl = process.env.NODE_ENV == "development" ? '//localhost:3000' : 'https://robocop.maeztra.com'
const getPerformance = (auth,query) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    const queryParams = new URLSearchParams(query)
    return axios(`${baseUrl}/jira/getPerformance?${queryParams}`, options)
}

const getPerformanceUserTotalHours = (auth,query) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    const queryParams = new URLSearchParams(query)
    return axios(`${baseUrl}/jira/getPerformanceUserTotalHours?${queryParams}`, options)
}

const getPerformanceUserQaHours = (auth,query) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    const queryParams = new URLSearchParams(query)
    return axios(`${baseUrl}/jira/getPerformanceUserQaHours?${queryParams}`, options)
}

const performanceReducerAssertivity = (auth,query) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    const queryParams = new URLSearchParams(query)
    return axios(`${baseUrl}/jira/getPerformanceAssertivity?${queryParams}`, options)
}

const getWorklogsByUserId = (auth,{from="",to="",userId="",pontoUserId=""}) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    const queryParams = new URLSearchParams({from,to,userId,pontoUserId})
    return axios(`${baseUrl}/jira/getWorklogsByUserId?${queryParams}`, options)
}

const getInternalWorklogs = (auth,{from="",to=""}) =>{
    let options = {
        headers: {
            "Content-Type": "application/json",
            "google-token": auth
        },
        method: "GET",
    }
    //rodando robocop nessa porta
    const queryParams = new URLSearchParams({from,to})
    return axios(`${baseUrl}/jira/getInternalWorklogs?${queryParams}`, options)
}
export {getInternalWorklogs,getPerformance,getPerformanceUserTotalHours,getPerformanceUserQaHours,performanceReducerAssertivity,getWorklogsByUserId}