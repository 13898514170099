import React ,{useContext, useEffect, useState}from 'react'
import { Col, Row } from 'react-bootstrap'
import CustomTimeModal from './CustomTimeModal'
import { DateRangeContext } from '../context/DateRangeContext'
import normalizeDate from '../utils/normalizeDate'
import { patchIssueJira } from '../../../services/jira';
import { UserTasks } from '../context/userTasks';
import convertDecimalToTime from '../utils/convertDecimalToTime'
import { AuthContext } from '../../../context/AuthContext'

export default function TimeSelector({task,noDate,index,disableEdit}) {
    const [hours,setHours]=useState(task.fields.timeoriginalestimate / 3600) 
    const [hoursPerDay,setHoursPerDay]=useState(task.hoursPerDay)
    const [willDeployDate,]=useState(task.willDeployDate)
    const[,,datesBetween]=useContext(DateRangeContext)
    const totalDays = parseInt(hours/datesBetween.length)
    const [initial, setInitial]=useState(datesBetween&&task.fields.customfield_10022?.length>0?0:undefined)
    const [newInitial, setNewInitial]=useState(false)
    const [final, setFinal]=useState(datesBetween?.length>0?datesBetween.length-1:undefined)
    const [newFinal, setNewFinal]=useState(false)
    const [hover, setHover]=useState()
    const [modalOpen, setModalOpen]=useState(false)
    const [hasNoStartDate,setHasNoStartDate]=useState(false)
    const [hasNoFinalDate,setHasNoFinalDate]=useState(false)
    const [,,,,taskReq,countWeekdays,updateIssue]=useContext(UserTasks)
    const [loading,setLoading] = useState(false)
    const [auth] = React.useContext(AuthContext);

    React.useEffect(()=>{
        setHoursPerDay(task.hoursPerDay)
    },[task.hoursPerDay])

    useEffect(()=>{
        if(noDate) return
        const initialDate = task.fields.customfield_10022
        const finalDate = task.fields.customfield_10023
        setHours(task.fields.timeoriginalestimate / 3600)
        if(datesBetween[0].date > new Date(normalizeDate(initialDate))){
            setHasNoStartDate(true)
            setInitial(0)
        }
        else setHasNoStartDate(false)
        
        if(datesBetween[datesBetween.length-1].date < new Date( normalizeDate(finalDate))){ 
            setHasNoFinalDate(true)
            setFinal(datesBetween.length-1)
        }
        else setHasNoFinalDate(false)
        

        datesBetween.forEach((date,index) => {   
            if(date.dateDataBase===initialDate){
                setInitial(index)
            }
            if(date.dateDataBase===finalDate){
                setFinal(index)
            }            
        });
   },[datesBetween,task])

    function handleMouseUp(e,i){
        if(loading) return
        e.preventDefault()
        if((newInitial &&i>final)||(newFinal &&i<initial)) return        
        if(newInitial){
            setInitial(i)
            sendToJira(datesBetween[i].dateDataBase,datesBetween[final].dateDataBase,index)
            const sd = normalizeDate(datesBetween[i].dateDataBase)
            const fd = normalizeDate(datesBetween[final].dateDataBase)
            const weekdays = countWeekdays(sd,fd)
            const hoursPerDay = convertDecimalToTime((task.fields.timeoriginalestimate / 3600)/(weekdays || 1))
            setHoursPerDay(hoursPerDay)
            setNewInitial(false)
        }
        if(newFinal){
            setFinal(i)
            sendToJira(datesBetween[initial].dateDataBase,datesBetween[i].dateDataBase,index)
            const sd = normalizeDate(datesBetween[initial].dateDataBase)
            const fd = normalizeDate(datesBetween[i].dateDataBase)
            const weekdays = countWeekdays(sd,fd)
            const hoursPerDay = convertDecimalToTime((task.fields.timeoriginalestimate / 3600)/(weekdays || 1))
            setHoursPerDay(hoursPerDay)
            setNewFinal(false)
        }    
    }

    // listening to changes and sending to jira
    async function sendToJira(init,fin,index){
        const data = {
            fields: {
                customfield_10022:init, 
                customfield_10023:fin
            }
        }
        setLoading(true)
        await new Promise((resolve,reject)=>{
            patchIssueJira(auth.accessToken,data,task.id).then((response) => {
                return resolve(response)
            }).catch(e=>reject(e)).finally(()=>setLoading(false))
        })
        updateIssue(task.key,init,fin)
    }

    const isFilled =(index,initial,final)=> index>=initial&&index<=final
    const isFilledClasses =(index,initial,final,item)=> `
    ${isFilled(index,initial,final)? `filled ${item.isWeekDay?`bg-gray700 ${willDeployDate?'striped-bg':''}`:'bg-gray100 weekend'}`:''} 
    ${index===initial && !hasNoStartDate? 'first':''} 
    ${index===final && !hasNoFinalDate? 'last':''}`
    
    function handleClick(e,i){
        e.preventDefault()
        if(initial!==undefined)return
        setInitial(i)
        setFinal(i+totalDays)  
    }
    function handleOpenModal(e){
        if(e.target.id!=='openModal')return
        setModalOpen(true)
    }
    function handleMouseOver(e,i){        
        e.preventDefault()
        if(initial===undefined) return 
        if(newInitial||newFinal){
            setHover(i)
        }
        else{
            setHover(undefined)
        }
    }

    function getIncompleteIssueMessage(){
        let counter = 0;
        if(!task.fields.customfield_10022) counter=counter+5;
        if(!task.fields.customfield_10023) counter=counter+7;
        if(!task.fields.timeoriginalestimate) counter=counter+11;
        
        switch(counter){
            case 5:
                return 'Data inicial não definida'
            case 7:
                return 'Data final não definida'
            case 11:
                return 'Tempo não definido'
            case 12:
                return 'Data inicial e final não definidas'
            case 16:
                return 'Data inicial e tempo não definidos'
            case 18:
                return 'Data final e tempo não definidos'
            case 23:
                return 'Data inicial, final e tempo não definidos'
            default:
                return 'Issue incompleta'
        } 
    }

    const editPosition =initial+(Math.round((final-initial)/2))
    return (
        <>
            {(noDate || !hours) && !willDeployDate?
                <div className='noTimeDefined flex position-relative' >
                    <div onClick={(e)=>disableEdit?null:handleOpenModal(e)} id="openModal">  <CustomTimeModal position={final-initial} task={task} modalOpen={modalOpen} setModalOpen={setModalOpen}  />{getIncompleteIssueMessage()}</div>
                </div>:
                <div className='position-relative w-100 timeSelectorWrapper'>
                    <Row className='TimeSelector flex position-relative' >
                    {datesBetween.map((item,index)=>
                        <Col key={index} 
                            onClick={(e)=>handleClick(e,index)}
                            onMouseUp={(e)=>handleMouseUp(e,index)}
                            className={`${isFilledClasses(index,initial,final,item)}  ${hover===index?"bg-gray200":""}`}
                            onMouseOver={(e)=>handleMouseOver(e,index)}                    
                        >
                            {/* botão para abrir o modal e editar  */}
                            {!disableEdit && !loading && editPosition === index && !willDeployDate? <CustomTimeModal position={final-initial} task={task}/>:null}  
                            {loading && editPosition === index && <div className={`RowLoading position-absolute top-0 start-${final-initial%2===0?'50':'0'}`}><div className="RowLoadingSpinner"></div></div>}
                            {/* botão de mudar a data inicial */}
                            {!disableEdit && !loading && index===initial && !hasNoStartDate && !hasNoFinalDate && !willDeployDate?<span onMouseDown={()=>setNewInitial(true)} className='initial' ></span>:null} 
                            
                            {/* texto do componente */}
                            {isFilled(index,initial,final)?`${item.isWeekDay?hoursPerDay:'00'} hs`:null}
                            {/* {!item.isWeekDay?'00 hs':null} */}

                            {/* botão de mudar a data final */}
                            {!disableEdit && !loading &&index===final && !hasNoFinalDate && !hasNoStartDate && !willDeployDate?<span onMouseDown={()=>setNewFinal(true)} className='final'></span>:null}                 
                        </Col>)}                        
                    </Row>            
                </div>
            }
        </>
  )

}