import React from 'react'
import { useContext } from 'react'
import { Col, Form } from 'react-bootstrap'
import { HideIncompleteTextContext } from '../context/HideIncompleteTextContext'

const HideTaskSwitch = () => {
    const [hideIncompleteText, setHideIncompleteText] = useContext(HideIncompleteTextContext)

  return (
    <Col className="d-flex col-3 flex-row gap-4 hover-pointer">
        <Form.Group controlId={`hide-tasks`} className="d-flex hover-pointer">
            <Form.Check type="switch" checked={hideIncompleteText} onChange={(e) => setHideIncompleteText(e.target.checked)} className="custom-switch hover-pointer"/>
            <Form.Label className="text-gray-700 fw-bold hideTasks hover-pointer">Ocultar tarefas sem cadastro datas</Form.Label>
        </Form.Group>
    </Col>
  )
}

export default HideTaskSwitch