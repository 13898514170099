import React from 'react'
import { Flex,IconButton,useToast } from "@chakra-ui/react";
import { UilFileAlt } from "@iconscout/react-unicons";
import { accountReport } from "../../services/jira";
import {invalidTokenToast} from "../../utils/toasts"

const y300 = { bg: "yellow.300", borderColor: "yellow.300" };
const y400 = { bg: "yellow.400", borderColor: "yellow.400" };

const baseUrl = process.env.NODE_ENV === "development" ? "//localhost:3000" : "https://robocop.maeztra.com";
const impReportBaseURL = process.env.NODE_ENV === "development" ? "//localhost:3007" : "https://mz-report.is2ssu07badoq.us-east-1.cs.amazonlightsail.com";
const AccountReportButton = ({auth,id,accountKey}) => {
    const toast = useToast()
    const [ loading,setLoading] = React.useState(false)
    function handleClick(){
        setLoading(true)
        accountReport(auth.accessToken,id,accountKey)
            .then(result=>{
                let url = result.data.url
                const isImp = accountKey.toLowerCase().endsWith("-imp")
                window.open((isImp?impReportBaseURL:baseUrl)+url, '_blank').focus();
                setLoading(false)
            })
            .catch(e=>{
                console.warn(e)
                toast(invalidTokenToast)
            })
    }

    return (
        <Flex mx={4} h={"100%"}>
            <IconButton isLoading={loading} size={"sm"} _hover={y300} onClick={handleClick} _active={y400} icon={<UilFileAlt />} />
        </Flex>
    )
}

export default AccountReportButton