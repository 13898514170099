import { Box, Checkbox, Flex, FormControl, Input, Select, Spinner, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { InputGroup } from 'react-bootstrap'
import { searchPontoUsers } from '../../services/jira'
import { AuthContext } from '../../context/AuthContext'
import _ from 'lodash'

const PontoUserDropdown = ({users,setUsers,idJira,thisUser,upsertUser}) => {
    const [auth] = React.useContext(AuthContext);
    const [pontoUsers,setPontoUsers] = React.useState([])
    const [userListToShow,setUserListToShow] = React.useState([])
    const [userReqIsLoading,setUserReqIsLoading] = React.useState(false)
    const [showDropdown,setShowDropdown] = React.useState(false)
    const [userInputValue,setUserInputValue] = React.useState(users.find(user => user.idJira === idJira)?.nomePontoMais || "")
    const [dummyUserInputValue,setDummyUserInputValue] = React.useState(users.find(user => user.idJira === idJira)?.nomePontoMais || "")
    const [clickedOnRow,setClickedOnRow] = React.useState(false)
    const debouceSearchTempoUsers = React.useCallback(_.debounce(searchTempoUsers, 500), []);


    React.useEffect(()=>{
        if(userInputValue?.length > 2 && !clickedOnRow && showDropdown && userInputValue !== dummyUserInputValue){
            debouceSearchTempoUsers(userInputValue)
        }
    },[userInputValue,clickedOnRow,auth,showDropdown,dummyUserInputValue,debouceSearchTempoUsers])

    React.useEffect(()=>{
        if(pontoUsers.length){
            let newList = pontoUsers.filter(user => !users.find(userInUsers => userInUsers.idPontoMais === user.idPontoMais))
            setUserListToShow(newList)
        }
    },[pontoUsers,users])

    function searchTempoUsers(userInputValue){
        setUserReqIsLoading(true)
        searchPontoUsers(auth.accessToken,userInputValue).then((response)=>{
            const pontoUsers = response.data.map(user => {
                return {
                    idPontoMais:user.id,
                    nomePontoMais:user.first_name + " " + user.last_name,
                }
            })
            setUserReqIsLoading(false)
            setPontoUsers(pontoUsers)
        })
    }


    function handleInputChange(e){
        if(e.target.value?.length > 2 && !clickedOnRow){
            setUserInputValue(e.target.value)
            setShowDropdown(true)
        }else{
            setUserInputValue("")
        }
        if(e.target.value?.length){
            setUserInputValue(e.target.value)
        }
    }

    function selectedUserToInput({idPontoMais,nomePontoMais}){
        setUserInputValue(nomePontoMais)
        setDummyUserInputValue(nomePontoMais)
        setClickedOnRow(true)
        setShowDropdown(false)
        //change user from users
        let newList = [...users]
        let userIndex = newList.findIndex(user => user.idJira === idJira)
        newList[userIndex].idPontoMais = String(idPontoMais)
        newList[userIndex].nomePontoMais = nomePontoMais
        setUsers(newList)
        upsertUser(newList[userIndex])
    }

    function handleInputBlur(){
        setUserInputValue(dummyUserInputValue)
        setTimeout(()=>{
            setShowDropdown(false)
        },600)
    }

  return (
    <Box position={"relative"} >
        <Input background={"white"} defaultValue={thisUser.nomePontoMais} value={userInputValue} onBlur={()=>handleInputBlur()} _focus={{background:"white"}} zIndex={3} onSelect={()=>setClickedOnRow(false)} onChange={(e)=>handleInputChange(e)} placeholder='Busque o nome do usuário' />
        {showDropdown && !userReqIsLoading &&
        <Box  background={"white"} border={"1px solid #e2e8f0"} borderRadius={"8px"} top={"40px"} position={"absolute"} width={"100%"} maxH={"200px"} overflow={"auto"} zIndex={4}>
            <Flex background={"white"}  my={2} flexDir={"column"} >
            {userListToShow.map((pontoUser, index) => (
                <Box _hover={{"background":"#e2e8f0","cursor":"pointer"}} onClick={(e)=>selectedUserToInput({...pontoUser})} py={1} px={2} isTruncated key={pontoUser.idPontoMais + "option" + index} value={pontoUser.idPontoMais}>{pontoUser.nomePontoMais}</Box>
                    ))}
            </Flex>
        </Box>}
        {showDropdown && userReqIsLoading && <Box zIndex={4} border={"1px solid #e2e8f0"} position={"absolute"} borderRadius={"8px"} borderTopRadius={0}  h={"40px"} w={"100%"} background={"white"}>
                <Spinner zIndex={4} position={"absolute"} top={"5px"}/>
            </Box>}
    </Box>
  )
}

export default PontoUserDropdown